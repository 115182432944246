import { Button, Link, Typography, withStyles } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { compose } from 'recompose';
import { withTheme } from '@material-ui/styles';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import Dialog from 'modules/common/dialog.component';
import Spinner from 'modules/common/spinner.component';

import { INTAKE_API_BASE_URL } from 'middleware/intakeApi';
import auth from 'auth/auth';

const styles = (theme) => ({
  buttonProgress: {
    width: 100,
  },
  dialogContent: {
    height: 200,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    flexGrow: 1,
  },
  downloadLink: {
    fontSize: '1.3rem',
    textAlign: 'center',
  },
});

class DownloadIntakePdfDialog extends Component {
  state = {
    isDownloading: true,
    fileBlobUrl: null,
  };

  componentDidMount() {
    this.downloadPdf();
  }

  downloadPdf = () => {
    this.setState({ isDownloading: true });
    let authToken = auth.getAccessToken();

    fetch(`${INTAKE_API_BASE_URL}intakeRecords/pdf/${this.props.intakeRecordId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: `Bearer ${authToken}`,
      },
    }).then((result) =>
      result.blob().then((blob) => {
        let url = window.URL.createObjectURL(blob);
        this.setState({ isDownloading: false, fileBlobUrl: url });
      }),
    );

    this.setState({ isDownloading: true });
  };

  render() {
    const { onCancel,
      classes,
      theme,
      intakeRecordId,
      authorizationId,
      ...rest } = this.props;

    return (
      <Dialog
        {...rest}
        title="Download Intake Record PDF"
        content={
          <div className={classes.dialogContent}>
            {this.state.isDownloading || this.state.fileBlobUrl === null ? 
              <>
                <Spinner logo />
                <Typography>Your PDF is being generated</Typography>
              </>
              : 
              <>
                <Link href={this.state.fileBlobUrl}
                  download={`${intakeRecordId}_${authorizationId}.pdf`}
                  className={classes.downloadLink}
                >
                  <FontAwesomeIcon
                    color={theme.palette.secondary.main}
                    icon={['fas', 'file-download']}
                    size="4x"
                  />
                  <br />
                Download PDF
                </Link>
              </>
            }
          </div>
        }
        actions={
          <>
            {!this.state.isDownloading && (
              <Button size="large" variant="outlined" color="secondary" onClick={onCancel}>
                Close
              </Button>
            )}
          </>
        }
      />
    );
  }
}

DownloadIntakePdfDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  intakeRecordId: PropTypes.number.isRequired,
  authorizationId: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  theme: PropTypes.object,
  open: PropTypes.bool.isRequired,
};

  
export default compose(withStyles(styles), withTheme)(DownloadIntakePdfDialog);
