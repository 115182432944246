const styles = (theme) => ({
  headerToggle: {
    marginLeft: theme.spacing(8),
  },
  stateSelect: {
    marginRight: theme.spacing(2),
    width: 200,
  },
  hoaSelect: {
    marginRight: theme.spacing(2),
    width: 300,
  },
  // TODO this is repeated between files
  inputRowSelect: {
    width: 140,
  },
  caption: {
    marginLeft: theme.spacing(2),
  },
  formControl: {
    marginRight: theme.spacing(2),
  },
});

export default styles;
