import produce from 'immer';

import {
  CLEAR_CACHED_INTAKE_RECORD,
  CLEAR_FOCUSED_GROUP,
  CLEAR_INTAKE_RECORD_VALIDATION,
  GET_INTAKE_FORM_METADATA_FAILURE,
  GET_INTAKE_FORM_METADATA_REQUEST,
  GET_INTAKE_FORM_METADATA_SUCCESS,
  GET_INTAKE_RECORD_FAILURE,
  GET_INTAKE_RECORD_REQUEST,
  GET_INTAKE_RECORD_SUCCESS,
  GET_INTAKE_SUMMARIES_FAILURE,
  GET_INTAKE_SUMMARIES_REQUEST,
  GET_INTAKE_SUMMARIES_SUCCESS,
  IMPORT_INTAKE_RECORD_FAILURE,
  IMPORT_INTAKE_RECORD_REQUEST,
  IMPORT_INTAKE_RECORD_SUCCESS,
  SET_FOCUSED_GROUP,
  SET_FOCUSED_TAB,
  SET_INTAKE_RECORD_URGENCY_FAILURE,
  SET_INTAKE_RECORD_URGENCY_REQUEST,
  SET_INTAKE_RECORD_URGENCY_SUCCESS,
  SET_VALIDATION_MODE,
  SUBMIT_INTAKE_RECORD_FAILURE,
  SUBMIT_INTAKE_RECORD_REQUEST,
  SUBMIT_INTAKE_RECORD_SUCCESS,
  UPDATE_INTAKE_RECORD_FAILURE,
  UPDATE_INTAKE_RECORD_REQUEST,
  UPDATE_INTAKE_RECORD_SUCCESS,
  VALIDATE_INTAKE_RECORD_FAILURE,
  VALIDATE_INTAKE_RECORD_REQUEST,
  VALIDATE_INTAKE_RECORD_SUCCESS,
} from './types';
import { ValidationMode } from './constants';
import { remapMetadata } from './utils';

const initialState = {
  intakeRecords: {},
  intakeSummaries: null,
  isLoading: false,
  metadata: null,
  requiresSummaryRefresh: false,
  isValidating: false,
  isSubmitting: false,
  validationResults: null,
  focusedTab: null,
  focusedGroup: null,
  isImportingRecord: false,
  validationMode: ValidationMode.Autosave,
};

export default produce((draft = initialState, action) => {
  switch (action.type) {
    case GET_INTAKE_RECORD_REQUEST:
    case GET_INTAKE_SUMMARIES_REQUEST:
    case GET_INTAKE_FORM_METADATA_REQUEST:
    case UPDATE_INTAKE_RECORD_REQUEST:
    case SET_INTAKE_RECORD_URGENCY_REQUEST:
      draft.isLoading = true;
      break;

    case VALIDATE_INTAKE_RECORD_REQUEST:
      draft.isValidating = true;
      draft.validationResults = null;
      break;

    case SUBMIT_INTAKE_RECORD_REQUEST:
      draft.isSubmitting = true;
      break;

    case GET_INTAKE_RECORD_FAILURE:
    case GET_INTAKE_SUMMARIES_FAILURE:
    case GET_INTAKE_FORM_METADATA_FAILURE:
    case UPDATE_INTAKE_RECORD_FAILURE:
    case SET_INTAKE_RECORD_URGENCY_FAILURE:
      draft.isLoading = false;
      break;

    case VALIDATE_INTAKE_RECORD_FAILURE:
      draft.isValidating = false;
      break;

    case SUBMIT_INTAKE_RECORD_FAILURE:
      draft.isSubmitting = false;
      break;

    case GET_INTAKE_FORM_METADATA_SUCCESS:
      draft.metadata = remapMetadata(action.response);
      draft.isLoading = false;
      break;

    case GET_INTAKE_RECORD_SUCCESS: {
      const record = action.response;
      draft.intakeRecords[record.id] = record;
      draft.isLoading = false;
      break;
    }

    case UPDATE_INTAKE_RECORD_SUCCESS: {
      const record = action.response;
      draft.intakeRecords[record.id] = record;
      draft.requiresSummaryRefresh = true;
      draft.isLoading = false;
      break;
    }

    case GET_INTAKE_SUMMARIES_SUCCESS:
      draft.intakeSummaries = action.response;
      draft.isLoading = false;
      draft.requiresSummaryRefresh = false;
      break;

    case SET_INTAKE_RECORD_URGENCY_SUCCESS: {
      const { intakeRecordId, intakeRecordUrgency } = action.context;
      const index = draft.intakeSummaries.findIndex((instance) => {
        return instance.intakeRecordId === intakeRecordId;
      });
      if (index >= 0) {
        draft.intakeSummaries[index].intakeRecordUrgency = intakeRecordUrgency;
      }
      draft.isLoading = false;
      break;
    }

    case VALIDATE_INTAKE_RECORD_SUCCESS:
      draft.isValidating = false;
      draft.validationResults = action.response;
      break;

    case SUBMIT_INTAKE_RECORD_SUCCESS:
      draft.requiresSummaryRefresh = true;
      draft.isSubmitting = false;
      break;

    case SET_FOCUSED_GROUP:
      draft.focusedGroup = action.response;
      break;

    case CLEAR_FOCUSED_GROUP:
      draft.focusedGroup = null;
      break;

    case SET_FOCUSED_TAB:
      draft.focusedTab = action.response;
      break;

    case IMPORT_INTAKE_RECORD_REQUEST:
      draft.isImportingRecord = true;
      break;

    case IMPORT_INTAKE_RECORD_SUCCESS:
    case IMPORT_INTAKE_RECORD_FAILURE:
      draft.isImportingRecord = false;
      break;

    case CLEAR_CACHED_INTAKE_RECORD:
      delete draft.intakeRecords[action.response];
      draft.validationResults = null;
      break;

    case CLEAR_INTAKE_RECORD_VALIDATION:
      draft.validationResults = null;
      break;

    case SET_VALIDATION_MODE:
      draft.validationMode = action.response;
      break;

    default:
      break;
  }

  return draft;
}, initialState);
