import { object } from 'yup';

import { formatForWire, formatZonedDateForInput } from 'utilities/common';
import { getDistance } from 'utilities/googleMapsClient';
import Log from 'utilities/log';

import { NULLABLE_STRING, plusMinusFiveYearsDateSchema } from 'modules/common/constants';
import { afterAuthDateSchema } from '../constants';
import Contact from './contact';
import Location from './location';

export default class PrimaryInformation {
  static schema = object().shape({
    transferee: Contact.schema,
    departureLocation: Location.schema,
    destinationLocation: Location.schema,
    relocationPolicyCodeId: NULLABLE_STRING,
    intakeRecordStatusId: NULLABLE_STRING,
    newJobStartDate: plusMinusFiveYearsDateSchema.concat(afterAuthDateSchema),
  });

  constructor(wireData) {
    const json = wireData || {};
    this.transferee = json.transferee;
    this.relocationPolicyCodeId = json.relocationPolicyCodeId;
    this.intakeRecordStatusId = json.intakeRecordStatusId;
    this.relocationPolicyCodes = json.relocationPolicyCodes ? this.sortPolicyCodes(json.relocationPolicyCodes) : [];
    this.relocationDistance = json.relocationDistance;
    this.newJobStartDate = formatZonedDateForInput(json.newJobStartDate);
    this.departureLocation = new Location({
      city: json.departureCity,
      state: json.departureStateCode,
      country: json.departureCountryCode,
    });
    this.destinationLocation = new Location({
      city: json.destinationCity,
      state: json.destinationStateCode,
      country: json.destinationCountryCode,
    });
  }

  sortPolicyCodes = (relocationPolicyCodes) => {
    const s = relocationPolicyCodes.sort((a, b) => { 
      if ((a.active && a.webViewable) && (!b.active || !b.webViewable)) return -1;
      if ((!a.active || !a.webViewable) && (b.active && b.webViewable)) return 1;
      return 0;
    });
    return s;
  }

  static fromWireData(wireData) {
    return new PrimaryInformation(wireData);
  }

  static generateErrors(errors) {
    if (!errors) {
      return null;
    }

    return {
      ...errors,
      departureLocation: Location.generateErrors(errors, 'departure') ? 'Invalid/missing address' : null,
      destinationLocation: Location.generateErrors(errors, 'destination') ? 'Invalid/missing address' : null,
    };
  }

  async calculateRelocationDistance() {
    try {
      const { value } = await getDistance(this.departureLocation, this.destinationLocation);
      this.relocationDistance = value;
    } catch (err) {
      Log.error('Unable to calculate distance', err);
    }
  }

  toWireData() {
    return formatForWire({
      transferee: this.transferee.toWireData(),
      departureLocation: this.departureLocation.toWireData(),
      destinationLocation: this.destinationLocation.toWireData(),
      relocationPolicyCodeId: this.relocationPolicyCodeId,
      intakeRecordStatusId: this.intakeRecordStatusId,
      relocationPolicyCodes: this.relocationPolicyCodes,
      relocationDistance: this.relocationDistance,
      newJobStartDate: this.newJobStartDate,
    });
  }
}
