import { Grid, Paper, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { Field } from 'formik';
import { compose } from 'recompose';
import { isEqual, values } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { Checkbox, TextField, ToggleSelect } from 'modules/intake/form';
import ExpansionPanel from 'modules/common/expansionPanel.component';
import ExpansionPanelDetails from 'modules/common/expansionPanelDetails.component';
import ExpansionPanelSummary from 'modules/common/expansionPanelSummary.component';
import Log from 'utilities/log';

import { DestinationInformation, FinalMoveBenefits, HouseHuntingBenefits } from 'modules/intake/models';
import { YES_NO_OPTIONS } from 'utilities/common';
import ConfirmationDialog from 'modules/common/confirmationDialog.component';
import styles from 'modules/intake/styles';

class NewDestinationForm extends Component {
  state = {
    showConfirmation: false,
  };

  shouldComponentUpdate(nextProps, nextState) {
    return !isEqual(this.props.newDestination, nextProps.newDestination) ||
      !isEqual(this.state, nextState);
  }

  renderHeader() {
    const { classes, newDestination, handleChange, handleBlur } = this.props;

    return (
      <div className="row align-center">
        <Typography variant="h3">New Destination Information</Typography>
        <Field
          component={Checkbox}
          className={classes.headerToggle}
          name="newDestination.isDestinationServiceInitiated"
          value={newDestination.isDestinationServiceInitiated}
          onChange={(event) => {
            // this value is the current state, not what it will change to if we allow it
            if (event.target.value === 'checked') {
              this.setState({ showConfirmation: true });
            } else {
              handleChange(event);
            }
          }}
          onBlur={handleBlur}
          label="Initiate Service"
        />
      </div>
    );
  }

  handleDisableService = () => {
    this.props.setFieldValue('newDestination', new DestinationInformation(), false);
    this.props.setFieldValue('benefits.houseHunting', new HouseHuntingBenefits(), false);
    this.props.setFieldValue('benefits.finalMove', new FinalMoveBenefits(), false);
    this.setState({ showConfirmation: false });
  };

  render() {
    Log.trace('RENDER', 'NewDestinationForm');
    const {
      classes,
      newDestination,
      handleChange,
      handleBlur,
      metadata: { familiarWithAreaTypes, housePropertyTypes, preferenceTypes },
    } = this.props;
    const { showConfirmation } = this.state;

    return (
      <div className="form-group">
        <ExpansionPanel expanded={newDestination.isDestinationServiceInitiated}>
          <ExpansionPanelSummary>{this.renderHeader()}</ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Grid container>
              <Grid item xs={12}>
                <Paper classes={{ root: 'card-root' }}>
                  <ExpansionPanel expanded={!!newDestination.isPurchasing}>
                    <ExpansionPanelSummary>
                      <div className="row align-center">
                        <Typography variant="h4">Purchasing</Typography>
                        <Field
                          component={ToggleSelect}
                          name="newDestination.isPurchasing"
                          value={newDestination.isPurchasing}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          options={YES_NO_OPTIONS}
                          label="Is Purchasing"
                          className={classes.headerToggle}
                        />
                      </div>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                      <Grid container spacing={1}>
                        <Grid item xs={12}>
                          <div className="row mb-1">
                            <Field
                              component={ToggleSelect}
                              name="newDestination.purchasingDestinationHomeTypeId"
                              value={newDestination.purchasingDestinationHomeTypeId}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              options={values(housePropertyTypes)}
                              variant="spread"
                              label="Property Type"
                              labelPlacement="top"
                            />
                          </div>
                          <div className="row mb-1">
                            <Field
                              component={ToggleSelect}
                              name="newDestination.purchasingFamiliarWithAreaId"
                              value={newDestination.purchasingFamiliarWithAreaId}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              options={values(familiarWithAreaTypes)}
                              label="Familiar with Area?"
                            />
                          </div>
                          <div className="row mb-1">
                            <Field
                              component={TextField}
                              name="newDestination.purchasingMinPrice"
                              label="Min Price"
                              value={newDestination.purchasingMinPrice}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              allowDecimal={true}
                              numeric
                              prefix="$"
                            />
                            <Field
                              component={TextField}
                              name="newDestination.purchasingMaxPrice"
                              label="Max Price"
                              value={newDestination.purchasingMaxPrice}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              allowDecimal={true}
                              numeric
                              prefix="$"
                            />
                          </div>
                          <div className="row mb-1">
                            <Field
                              component={TextField}
                              name="newDestination.purchasingBedroomsPreferred"
                              label="Bedroom Preference"
                              value={newDestination.purchasingBedroomsPreferred}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              numeric
                            />
                            <Field
                              component={TextField}
                              name="newDestination.purchasingBathroomsPreferred"
                              label="Bathroom Preference"
                              value={newDestination.purchasingBathroomsPreferred}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              numeric
                            />
                            <Field
                              component={TextField}
                              name="newDestination.purchasingSquareFootage"
                              label="Square Feet"
                              value={newDestination.purchasingSquareFootage}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              numeric
                            />
                          </div>
                          <div className="row mb-1">
                            <Field
                              component={ToggleSelect}
                              name="newDestination.purchasingFireplacePreferenceId"
                              value={newDestination.purchasingFireplacePreferenceId}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              options={values(preferenceTypes)}
                              label="Fireplace Preference?"
                            />
                          </div>
                          <div className="row mb-1">
                            <Field
                              component={ToggleSelect}
                              name="newDestination.purchasingGaragePreferenceId"
                              value={newDestination.purchasingGaragePreferenceId}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              options={values(preferenceTypes)}
                              label="Garage Preference?"
                            />
                          </div>
                          <div className="row mb-1">
                            <Field
                              component={ToggleSelect}
                              name="newDestination.purchasingBasementPreferenceId"
                              value={newDestination.purchasingBasementPreferenceId}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              options={values(preferenceTypes)}
                              label="Basement Preference?"
                            />
                          </div>
                        </Grid>
                        <Grid item xs={12} md={9}>
                          <Field
                            component={ToggleSelect}
                            name="newDestination.purchasingHasSchoolRequest"
                            value={newDestination.purchasingHasSchoolRequest}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            options={YES_NO_OPTIONS}
                            label="School Request?"
                          />
                        </Grid>
                        <Grid item xs={12} md={9}>
                          <Field
                            component={TextField}
                            name="newDestination.purchasingImportantFeaturesSpecialRequests"
                            label="Important Features/Special Requests/Preferences"
                            value={newDestination.purchasingImportantFeaturesSpecialRequests}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            fullWidth
                          />
                        </Grid>
                      </Grid>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                </Paper>
              </Grid>
              <Grid item xs={12}>
                <Paper classes={{ root: 'card-root' }}>
                  <ExpansionPanel expanded={!!newDestination.isRenting}>
                    <ExpansionPanelSummary>
                      <div className="row align-center">
                        <Typography variant="h4">Renting</Typography>
                        <Field
                          component={ToggleSelect}
                          name="newDestination.isRenting"
                          value={newDestination.isRenting}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          options={YES_NO_OPTIONS}
                          className={classes.headerToggle}
                          label="Is Renting"
                        />
                      </div>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                      <Grid container spacing={1}>
                        <Grid item xs={12}>
                          <div className="row mb-1">
                            <Field
                              component={TextField}
                              name="newDestination.rentingMinPrice"
                              label="Min Price"
                              value={newDestination.rentingMinPrice}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              allowDecimal={true}
                              numeric
                              prefix="$"
                            />
                            <Field
                              component={TextField}
                              name="newDestination.rentingMaxPrice"
                              label="Max Price"
                              value={newDestination.rentingMaxPrice}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              allowDecimal={true}
                              numeric
                              prefix="$"
                            />
                          </div>
                          <div className="row mb-1">
                            <Field
                              component={TextField}
                              name="newDestination.rentingBedroomsPreferred"
                              label="Bedroom Preference"
                              value={newDestination.rentingBedroomsPreferred}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              numeric
                            />
                            <Field
                              component={TextField}
                              name="newDestination.rentingBathroomsPreferred"
                              label="Bathroom Preference"
                              value={newDestination.rentingBathroomsPreferred}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              numeric
                            />
                          </div>
                        </Grid>
                        <Grid item xs={12} md={9}>
                          <Field
                            component={ToggleSelect}
                            name="newDestination.rentingHasSchoolRequest"
                            value={newDestination.rentingHasSchoolRequest}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            options={YES_NO_OPTIONS}
                            label="School Request?"
                          />
                        </Grid>
                      </Grid>
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                </Paper>
              </Grid>
            </Grid>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ConfirmationDialog
          open={showConfirmation}
          title="Disable Service?"
          confirmLabel="Yes"
          cancelLabel="No"
          onConfirm={this.handleDisableService}
          onCancel={() => this.setState({ showConfirmation: false })}
        >
          <Typography>
            If you disable this service, you will lose all data entered in this section and any timeline entries related to this service. <br /><br />Do you want to proceed?
          </Typography>
        </ConfirmationDialog>
      </div>
    );
  }
}

NewDestinationForm.propTypes = {
  classes: PropTypes.object.isRequired,
  newDestination: PropTypes.object.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  metadata: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired,
};

export default compose(withStyles(styles))(NewDestinationForm);
