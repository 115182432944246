import { CALL_INTAKE_API } from 'middleware/intakeApi';

import {
  ADD_COMMENT_FAILURE,
  ADD_COMMENT_REQUEST,
  ADD_COMMENT_SUCCESS,
  ADD_NOTE_FAILURE,
  ADD_NOTE_REQUEST,
  ADD_NOTE_SUCCESS,
  ASSIGN_INTAKE_RECORD_FAILURE,
  ASSIGN_INTAKE_RECORD_REQUEST,
  ASSIGN_INTAKE_RECORD_SUCCESS,
  CLEAR_CACHED_INTAKE_RECORD,
  CLEAR_FOCUSED_GROUP,
  CLEAR_INTAKE_RECORD_VALIDATION,
  DELETE_INTAKE_RECORD_FAILURE,
  DELETE_INTAKE_RECORD_REQUEST,
  DELETE_INTAKE_RECORD_SUCCESS,
  GET_INTAKE_FORM_METADATA_FAILURE,
  GET_INTAKE_FORM_METADATA_REQUEST,
  GET_INTAKE_FORM_METADATA_SUCCESS,
  GET_INTAKE_RECORD_FAILURE,
  GET_INTAKE_RECORD_REQUEST,
  GET_INTAKE_RECORD_SUCCESS,
  GET_INTAKE_SUMMARIES_FAILURE,
  GET_INTAKE_SUMMARIES_REQUEST,
  GET_INTAKE_SUMMARIES_SUCCESS,
  IMPORT_INTAKE_RECORD_FAILURE,
  IMPORT_INTAKE_RECORD_REQUEST,
  IMPORT_INTAKE_RECORD_SUCCESS,
  SET_FOCUSED_GROUP,
  SET_FOCUSED_TAB,
  SET_INTAKE_RECORD_URGENCY_FAILURE,
  SET_INTAKE_RECORD_URGENCY_REQUEST,
  SET_INTAKE_RECORD_URGENCY_SUCCESS,
  SET_VALIDATION_MODE,
  SUBMIT_INTAKE_RECORD_FAILURE,
  SUBMIT_INTAKE_RECORD_REQUEST,
  SUBMIT_INTAKE_RECORD_SUCCESS,
  UPDATE_INTAKE_RECORD_FAILURE,
  UPDATE_INTAKE_RECORD_REQUEST,
  UPDATE_INTAKE_RECORD_SUCCESS,
  VALIDATE_INTAKE_RECORD_FAILURE,
  VALIDATE_INTAKE_RECORD_REQUEST,
  VALIDATE_INTAKE_RECORD_SUCCESS,
} from './types';

export const getIntakeFormMetadata = () => {
  return {
    [CALL_INTAKE_API]: {
      types: [GET_INTAKE_FORM_METADATA_REQUEST, GET_INTAKE_FORM_METADATA_SUCCESS, GET_INTAKE_FORM_METADATA_FAILURE],
      authenticated: true,
      endpoint: 'intakeRecords/metadata',
      method: 'GET',
    },
  };
};

export const getIntakeRecord = (id) => {
  return {
    [CALL_INTAKE_API]: {
      types: [GET_INTAKE_RECORD_REQUEST, GET_INTAKE_RECORD_SUCCESS, GET_INTAKE_RECORD_FAILURE],
      authenticated: true,
      endpoint: `intakeRecords/${id}`,
      method: 'GET',
    },
  };
};

export const updateIntakeRecord = (record) => {
  return {
    [CALL_INTAKE_API]: {
      types: [UPDATE_INTAKE_RECORD_REQUEST, UPDATE_INTAKE_RECORD_SUCCESS, UPDATE_INTAKE_RECORD_FAILURE],
      authenticated: true,
      endpoint: 'intakeRecords',
      method: 'PUT',
      payload: record,
    },
  };
};

export const setIntakeRecordUrgency = (intakeRecordId, intakeRecordUrgency) => {
  return {
    [CALL_INTAKE_API]: {
      types: [SET_INTAKE_RECORD_URGENCY_REQUEST, SET_INTAKE_RECORD_URGENCY_SUCCESS, SET_INTAKE_RECORD_URGENCY_FAILURE],
      authenticated: true,
      endpoint: `intakeRecords/urgency/${intakeRecordId}/${intakeRecordUrgency}`,
      method: 'POST',
      context: {
        intakeRecordId,
        intakeRecordUrgency,
      },
    },
  };
};

export const getIntakeSummaries = () => {
  return {
    [CALL_INTAKE_API]: {
      types: [GET_INTAKE_SUMMARIES_REQUEST, GET_INTAKE_SUMMARIES_SUCCESS, GET_INTAKE_SUMMARIES_FAILURE],
      authenticated: true,
      endpoint: 'intakeSummaries',
      method: 'GET',
    },
  };
};

export const addNoteToRecord = (note, record) => {
  return {
    [CALL_INTAKE_API]: {
      types: [ADD_NOTE_REQUEST, ADD_NOTE_SUCCESS, ADD_NOTE_FAILURE],
      authenticated: true,
      endpoint: 'intakeNotes',
      method: 'POST',
      payload: {
        intakeRecordId: record.intakeRecordId,
        note,
      },
    },
  };
};

export const addCommentToRecord = (comment, record) => {
  return {
    [CALL_INTAKE_API]: {
      types: [ADD_COMMENT_REQUEST, ADD_COMMENT_SUCCESS, ADD_COMMENT_FAILURE],
      authenticated: true,
      endpoint: 'intakeAuthorizationComments',
      method: 'POST',
      payload: {
        intakeRecordId: record.intakeRecordId,
        ...comment,
      },
    },
  };
};

export const assignIntakeRecordToRelocationConsultant = (record, relocationConsultant) => {
  return {
    [CALL_INTAKE_API]: {
      types: [ASSIGN_INTAKE_RECORD_REQUEST, ASSIGN_INTAKE_RECORD_SUCCESS, ASSIGN_INTAKE_RECORD_FAILURE],
      authenticated: true,
      endpoint: `relocationConsultants/${relocationConsultant.id}/intakeRecords/${record.intakeRecordId}`,
      method: 'PUT',
    },
  };
};

export const deleteIntakeRecord = (record) => {
  return {
    [CALL_INTAKE_API]: {
      types: [DELETE_INTAKE_RECORD_REQUEST, DELETE_INTAKE_RECORD_SUCCESS, DELETE_INTAKE_RECORD_FAILURE],
      authenticated: true,
      endpoint: `intakeRecords/${record.intakeRecordId}`,
      method: 'DELETE',
    },
  };
};

export const validateIntakeRecord = (record) => {
  return {
    [CALL_INTAKE_API]: {
      types: [VALIDATE_INTAKE_RECORD_REQUEST, VALIDATE_INTAKE_RECORD_SUCCESS, VALIDATE_INTAKE_RECORD_FAILURE],
      authenticated: true,
      endpoint: `intakeRecords/validate/${record.id}`,
      method: 'POST',
      payload: record,
    },
  };
};

export const submitIntakeRecord = (intakeRecordId, hasWebAccess, bankingInfoRequired) => {
  return {
    [CALL_INTAKE_API]: {
      types: [SUBMIT_INTAKE_RECORD_REQUEST, SUBMIT_INTAKE_RECORD_SUCCESS, SUBMIT_INTAKE_RECORD_FAILURE],
      authenticated: true,
      endpoint: `intakeRecords/submit/${intakeRecordId}`,
      method: 'POST',
      payload: { hasWebAccess, bankingInfoRequired },
    },
  };
};

export const setFocusedTab = (tab) => ({
  type: SET_FOCUSED_TAB,
  response: tab,
});

export const setFocusedGroup = (group) => ({
  type: SET_FOCUSED_GROUP,
  response: group,
});

export const clearFocusedGroup = () => ({
  type: CLEAR_FOCUSED_GROUP,
});

export const importIntakeRecord = (authId) => {
  return {
    [CALL_INTAKE_API]: {
      types: [IMPORT_INTAKE_RECORD_REQUEST, IMPORT_INTAKE_RECORD_SUCCESS, IMPORT_INTAKE_RECORD_FAILURE],
      authenticated: true,
      endpoint: `intakeRecords/import/${authId}`,
      method: 'POST',
    },
  };
};

export const clearCachedIntakeRecord = (id) => ({
  type: CLEAR_CACHED_INTAKE_RECORD,
  response: id,
});

export const clearIntakeRecordValidation = (id) => ({
  type: CLEAR_INTAKE_RECORD_VALIDATION,
});

export const setValidationMode = (mode) => ({
  type: SET_VALIDATION_MODE,
  response: mode,
});
