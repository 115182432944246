import { object } from 'yup';

import { formatDateForInput, formatForWire } from 'utilities/common';
import { plusMinusFiveYearsDateSchema } from 'modules/common/constants';

export default class TemporaryLivingBenefits {
  static schema = object().shape({
    estimatedStartDate: plusMinusFiveYearsDateSchema,
    estimatedEndDate: plusMinusFiveYearsDateSchema,
  });

  constructor(wireData) {
    const json = wireData || {};
    this.estimatedStartDate = formatDateForInput(json.estimatedStartDate);
    this.estimatedEndDate = formatDateForInput(json.estimatedEndDate);
  }

  static fromWireData(wireData) {
    return new TemporaryLivingBenefits(wireData);
  }

  static generateErrors(errors) {
    return errors;
  }

  toWireData() {
    return formatForWire({
      estimatedStartDate: this.estimatedStartDate,
      estimatedEndDate: this.estimatedEndDate,
    });
  }
}
