import { Divider, Grid, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { Field } from 'formik';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { isEqual, values } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { IS_RENTED_OPTIONS, YES_NO_OPTIONS } from 'utilities/common';
import { ToggleSelect } from 'modules/intake/form';
import { showToast } from 'modules/layout/layout.actions';
import ConfirmationDialog from 'modules/common/confirmationDialog.component';
import Log from 'utilities/log';

import { HomesaleProcessBenefits } from 'modules/intake/models';
import PropertyCreditBalanceForm from './propertyCreditBalanceForm.component';
import PropertyEscrowForm from './propertyEscrowForm.component';
import PropertyExteriorDetailsForm from './propertyExteriorDetailsForm.component';
import PropertyFeaturesForm from './propertyFeaturesForm.component';
import PropertyFloodInsuranceForm from './propertyFloodInsuranceForm.component';
import PropertyHoaForm from './propertyHoaForm.component';
import PropertyInspectionForm from './propertyInspectionForm.component';
import PropertyLeaseBreakForm from './propertyLeaseBreakForm.component';
import PropertyNewConstructionForm from './propertyNewConstructionForm.component';
import PropertyNotesForm from './propertyNotesForm.component';
import PropertyPricingForm from './propertyPricingForm.component';
import PropertyTitleLegalNameForm from './propertyTitleLegalNameForm.component';
import styles from 'modules/intake/styles';

const renderDivider = (title) => (
  <Grid item xs={12}>
    <div className="my-2">
      <Divider variant="fullWidth" className="full-width" />
    </div>
    <Typography variant="h4">{title}</Typography>
  </Grid>
);

class DepartureProperty extends Component {
  state = {
    nextOwnershipEvent: null,
    nextSellingEvent: null,
  };

  shouldComponentUpdate(nextProps, nextState) {
    return (
      !isEqual(this.props.departureProperty, nextProps.departureProperty) ||
      this.props.isSubmitting !== nextProps.isSubmitting ||
      !isEqual(this.state, nextState)
    );
  }

  renderHeader() {
    const { departureProperty, handleChange, handleBlur } = this.props;

    return (
      <>
        <Typography gutterBottom variant="h3">
          Departure Property
        </Typography>
        
        <div className="row mb-2">
          <Field
            component={ToggleSelect}
            name="departureProperty.isRental"
            value={departureProperty.isRental}
            onChange={(event) => {
              // show message if it was previously set to true or false
              if (departureProperty.isRental !== null) {
                this.setState({ nextOwnershipEvent: event });
              } else {
                handleChange(event);
              }
            }}
            onBlur={handleBlur}
            options={IS_RENTED_OPTIONS}
            label="Do you currently own or rent your home?"
          />
        </div>
      </>
    );
  }

  renderRentedProperty() {
    const { departureProperty, handleChange, handleBlur, metadata } = this.props;

    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h4">Lease Break</Typography>
        </Grid>
        <Grid item md={12}>
          <PropertyLeaseBreakForm
            metadata={metadata}
            leaseBreak={departureProperty.leaseBreak}
            handleChange={handleChange}
            handleBlur={handleBlur}
            rootPath="departureProperty.leaseBreak"
          />
        </Grid>
      </Grid>
    );
  }

  setHomesaleType = (isEligible, isSelling) => {
    const { metadata, setFieldValue } = this.props;
    if (isSelling && !isEligible) {
      const hmaHomeSaleType = values(metadata.homesaleTypes).find((instance) => String(instance.description).toUpperCase() === 'HMA');
      if (hmaHomeSaleType) {
        setFieldValue('departureProperty.homesaleTypeId', hmaHomeSaleType.id, false);
      }
    }
  };

  renderOwnedProperty() {
    const { departureProperty, handleChange, handleBlur, metadata } = this.props;

    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <div className="row">
            <Field
              component={ToggleSelect}
              name="departureProperty.isEligibleForHomesale"
              value={departureProperty.isEligibleForHomesale}
              onChange={(e) => {
                this.setHomesaleType(!departureProperty.isEligibleForHomesale, departureProperty.isSellingDepartureProperty);
                handleChange(e);
              }}
              onBlur={handleBlur}
              options={YES_NO_OPTIONS}
              label="Is the Transferee eligible for Home Sale services?"
              alignCenter
            />
            <Field
              component={ToggleSelect}
              name="departureProperty.homesaleTypeId"
              value={departureProperty.homesaleTypeId}
              onChange={handleChange}
              onBlur={handleBlur}
              options={values(metadata.homesaleTypes)}
              variant="spread"
              disabled={!departureProperty.isEligibleForHomesale}
              label="What Home Sale program type is the Transferee eligible for?"
              labelPlacement="top"
            />
          </div>
        </Grid>

        <Grid item xs={12}>
          <Field
            component={ToggleSelect}
            name="departureProperty.isSellingDepartureProperty"
            value={departureProperty.isSellingDepartureProperty}
            onChange={(event) => {
              // show message if it was previously set to true or false
              if (departureProperty.isSellingDepartureProperty !== null) {
                this.setState({ nextSellingEvent: event });
              } else {
                this.setHomesaleType(departureProperty.isEligibleForHomesale, !departureProperty.isSellingDepartureProperty);
                handleChange(event);
              }
            }}
            onBlur={handleBlur}
            options={YES_NO_OPTIONS}
            label="Are you planning to sell your home?"
          />
        </Grid>

        {departureProperty.isSellingDepartureProperty && this.renderDeparturePropertyDetails()}
      </Grid>
    );
  }

  renderDeparturePropertyDetails() {
    const { departureProperty, handleChange, handleBlur, submitForm, metadata, isSubmitting } = this.props;

    return (
      <Grid container spacing={2}>
        {renderDivider('Legal/Financial/Insurance')}

        <Grid item lg={6} md={12}>
          <PropertyHoaForm
            metadata={metadata}
            hoa={departureProperty.hoa}
            handleChange={handleChange}
            handleBlur={handleBlur}
            rootPath="departureProperty.hoa"
          />
          <PropertyCreditBalanceForm
            metadata={metadata}
            balance={departureProperty.balance}
            handleChange={handleChange}
            handleBlur={handleBlur}
            rootPath="departureProperty.balance"
            isSubmitting={isSubmitting}
          />
          <PropertyEscrowForm
            metadata={metadata}
            escrow={departureProperty.escrow}
            handleChange={handleChange}
            handleBlur={handleBlur}
            rootPath="departureProperty.escrow"
          />
          <PropertyFloodInsuranceForm
            metadata={metadata}
            floodInsurance={departureProperty.floodInsurance}
            handleChange={handleChange}
            handleBlur={handleBlur}
            rootPath="departureProperty.floodInsurance"
          />
        </Grid>

        <Grid item lg={6} md={12}>
          <PropertyTitleLegalNameForm
            metadata={metadata}
            titleLegalName={departureProperty.titleLegalName}
            handleChange={handleChange}
            handleBlur={handleBlur}
            submitForm={submitForm}
            rootPath="departureProperty.titleLegalName"
            isSubmitting={isSubmitting}
          />
        </Grid>

        {renderDivider('Construction')}

        <Grid item lg={6} md={12}>
          <PropertyNewConstructionForm
            metadata={metadata}
            newConstruction={departureProperty.newConstruction}
            handleChange={handleChange}
            handleBlur={handleBlur}
            rootPath="departureProperty.newConstruction"
          />
        </Grid>

        <Grid item xs={12}>
          <PropertyExteriorDetailsForm
            metadata={metadata}
            exteriorDetails={departureProperty.exteriorDetails}
            handleChange={handleChange}
            handleBlur={handleBlur}
            rootPath="departureProperty.exteriorDetails"
          />
        </Grid>

        {renderDivider('Interior Features')}

        <Grid item xs={12}>
          <PropertyFeaturesForm
            metadata={metadata}
            features={departureProperty.features}
            handleChange={handleChange}
            handleBlur={handleBlur}
            rootPath="departureProperty.features"
          />
        </Grid>

        {renderDivider('Other Items')}

        <Grid item xs={12}>
          <PropertyNotesForm
            metadata={metadata}
            notes={departureProperty.notes}
            handleChange={handleChange}
            handleBlur={handleBlur}
            rootPath="departureProperty.notes"
          />
        </Grid>

        <Grid item xs={12}>
          <PropertyInspectionForm
            metadata={metadata}
            inspection={departureProperty.inspection}
            handleChange={handleChange}
            handleBlur={handleBlur}
            rootPath="departureProperty.inspection"
          />
        </Grid>

        <Grid item xs={12}>
          <PropertyPricingForm
            metadata={metadata}
            pricing={departureProperty.pricing}
            balance={departureProperty.balance}
            handleChange={handleChange}
            handleBlur={handleBlur}
            rootPath="departureProperty.pricing"
          />
        </Grid>
      </Grid>
    );
  }

  handleOwnershipChange = () => {
    const { handleChange } = this.props;
    handleChange(this.state.nextOwnershipEvent);
    this.props.setFieldValue('benefits.homesaleProcess', new HomesaleProcessBenefits(), false);
    this.setState({ nextOwnershipEvent: null });
  };

  handleSellingChange = () => {
    const { departureProperty, handleChange } = this.props;
    this.setHomesaleType(departureProperty.isEligibleForHomesale, !departureProperty.isSellingDepartureProperty);
    this.props.setFieldValue('benefits.homesaleProcess', new HomesaleProcessBenefits(), false);
    handleChange(this.state.nextSellingEvent);
    this.setState({ nextSellingEvent: null });
  };

  render() {
    Log.trace('RENDER', 'DepartureProperty');
    const { departureProperty } = this.props;
    const { nextOwnershipEvent, nextSellingEvent } = this.state;

    return (
      <div className="form-group">
        {this.renderHeader()}
        {departureProperty.isRental && this.renderRentedProperty()}
        {departureProperty.isRental === false && this.renderOwnedProperty()}
        <ConfirmationDialog
          open={!!nextOwnershipEvent}
          title="Change Ownership Type?"
          confirmLabel="Yes"
          cancelLabel="No"
          onConfirm={this.handleOwnershipChange}
          onCancel={() => this.setState({ nextOwnershipEvent: null })}
        >
          <Typography>
            If you change the ownership type, you will lose all data entered in this section and any timeline entries related to homesale. <br /><br />Do you want to proceed?
          </Typography>
        </ConfirmationDialog>
        <ConfirmationDialog
          open={!!nextSellingEvent}
          title="Change Decision to Sell?"
          confirmLabel="Yes"
          cancelLabel="No"
          onConfirm={this.handleSellingChange}
          onCancel={() => this.setState({ nextSellingEvent: null })}
        >
          <Typography>
            If you change the decision to sell, you will lose all data entered in this section and any timeline entries related to homesale. <br /><br /> Do you want to proceed?
          </Typography>
        </ConfirmationDialog>
      </div>
    );
  }
}

DepartureProperty.propTypes = {
  classes: PropTypes.object.isRequired,
  departureProperty: PropTypes.object.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  showToast: PropTypes.func.isRequired,
  metadata: PropTypes.object.isRequired,
  submitForm: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
};

export default compose(
  withStyles(styles),
  connect(null, {
    showToast,
  }),
)(DepartureProperty);
