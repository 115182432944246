import { object, string } from 'yup';

import { formatForWire, isType, parseNumbers } from 'utilities/common';

import { MAX_AGE } from '../constants';
import { MAX_LENGTH_ERROR_MESSAGE, MIN_LENGTH_ERROR_MESSAGE, REQUIRED_STRING } from 'modules/common/constants';

const childAgeSchema = string().test(
  'is-valid-age',
  'Out of expected range',
  function (value) {
    // TODO should be required if contact is a child
    if (value === '') {
      return true;
    }

    const number = parseInt(value, 10);
    if (isNaN(number)) {
      return false;
    }
    return number >= 0 && number <= MAX_AGE;
  },
);

export const isTransfereeContactType = (contactTypeId, contactMetadata) => {
  return isType('TRANSFEREE', contactTypeId, contactMetadata);
};

export const isChildContactType = (contactTypeId, contactMetadata) => {
  return isType('CHILD', contactTypeId, contactMetadata);
};

export const isSpouseContactType = (contactTypeId, contactMetadata) => {
  return isType('SPOUSE', contactTypeId, contactMetadata) || isType('PARTNER', contactTypeId, contactMetadata);
};

export default class Contact {
  static schema = object().shape({
    firstName: REQUIRED_STRING
      .min(2, MIN_LENGTH_ERROR_MESSAGE)
      .max(30, MAX_LENGTH_ERROR_MESSAGE),
    lastName: REQUIRED_STRING
      .min(2, MIN_LENGTH_ERROR_MESSAGE)
      .max(30, MAX_LENGTH_ERROR_MESSAGE),
    contactTypeId: REQUIRED_STRING,
    age: childAgeSchema,
    nickname: string(),
  });

  constructor(wireData, metadata) {
    this.metadata = metadata;
    const json = wireData || {};

    this.id = json.id || null;
    this.firstName = json.firstName || '';
    this.lastName = json.lastName || '';
    this.nickname = json.nickname || '';
    this.age = json.age || '';
    this.contactTypeId = json.contactTypeId || '';
  }

  static fromWireData(wireData, metadata) {
    return new Contact(wireData, metadata);
  }

  toWireData() {
    return formatForWire({
      id: this.id,
      firstName: this.firstName,
      lastName: this.lastName,
      nickname: this.nickname,
      contactTypeId: this.contactTypeId,
      ...parseNumbers({
        age: this.age,
      }),
    });
  }

  isTransferee() {
    return isTransfereeContactType(this.contactTypeId, this.metadata);
  }

  isChild() {
    return isChildContactType(this.contactTypeId, this.metadata);
  }

  isSpouse() {
    return isSpouseContactType(this.contactTypeId, this.metadata);
  }
}
