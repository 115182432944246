import { Grid, Paper, withStyles } from '@material-ui/core';

import { Field } from 'formik';
import { isEqual } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { ToggleSelect } from 'modules/intake/form';
import { YES_NO_OPTIONS } from 'utilities/common';
import Hint from 'modules/common/hint.component';
import Log from 'utilities/log';

import styles from 'modules/intake/styles';

class PropertyNewConstructionForm extends Component {
  shouldComponentUpdate(nextProps) {
    return !isEqual(this.props.newConstruction, nextProps.newConstruction);
  }

  render() {
    Log.trace('RENDER', 'PropertyNewConstructionForm');
    const {
      newConstruction,
      handleChange,
      handleBlur,
      rootPath,
    } = this.props;

    return (
      <Paper classes={{ root: 'card-root' }}>
        <Grid container item xs={12} direction="column" alignItems="flex-start">
          <Field
            component={ToggleSelect}
            name={`${rootPath}.isNewConstruction`}
            value={newConstruction.isNewConstruction}
            onChange={handleChange}
            onBlur={handleBlur}
            options={YES_NO_OPTIONS}
            label="New Construction"
          />
          {newConstruction.isNewConstruction &&
            <Hint>Fully Assessed for Land and Dwelling?</Hint>
          }
        </Grid>
      </Paper>
    );
  }
}

PropertyNewConstructionForm.propTypes = {
  newConstruction: PropTypes.object.isRequired,
  rootPath: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  metadata: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(PropertyNewConstructionForm);
