import { boolean, object } from 'yup';

import { formatForWire, isNotDefined } from 'utilities/common';

import { MAX_LENGTH_ERROR_MESSAGE, REQUIRED_STRING } from 'modules/common/constants';

export default class Phone {
  static schema = object().shape({
    phoneNumber: REQUIRED_STRING.max(100, MAX_LENGTH_ERROR_MESSAGE),
    phoneTypeId: REQUIRED_STRING,
    contactInfoOwnerTypeId: REQUIRED_STRING,
    isPrimary: boolean(),
  });

  constructor(wireData) {
    const json = wireData || {};
    this.id = json.id;
    this.phoneNumber = json.phoneNumber || '';
    this.phoneTypeId = isNotDefined(json.phoneTypeId) ? '': json.phoneTypeId;
    this.contactInfoOwnerTypeId = isNotDefined(json.contactInfoOwnerTypeId) ? '': json.contactInfoOwnerTypeId;
    this.isPrimary = !!json.isPrimary;
  }

  static fromWireData(wireData) {
    return new Phone(wireData);
  }

  toWireData() {
    return formatForWire({
      id: this.id,
      phoneNumber: this.phoneNumber,
      phoneTypeId: this.phoneTypeId,
      contactInfoOwnerTypeId: this.contactInfoOwnerTypeId,
      isPrimary: this.isPrimary,
    });
  }
}
