import { Button, Divider, FormLabel, Grid, Paper, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import { Field, FieldArray } from 'formik';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { isEqual } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { AutosaveDeleteButton, Checkbox, TextField, ToggleSelect } from 'modules/intake/form';
import { YES_NO_OPTIONS } from 'utilities/common';
import { showToast } from 'modules/layout/layout.actions';
import ExpansionPanel from 'modules/common/expansionPanel.component';
import ExpansionPanelDetails from 'modules/common/expansionPanelDetails.component';
import ExpansionPanelSummary from 'modules/common/expansionPanelSummary.component';
import Log from 'utilities/log';

import { HhgInformation, Vehicle } from 'modules/intake/models';
import ConfirmationDialog from 'modules/common/confirmationDialog.component';
import styles from 'modules/intake/styles';

const addVehicle = (arrayHelpers) => () => {
  arrayHelpers.push(new Vehicle());
};

class HhgInformationForm extends Component {
  state = {
    showConfirmation: false,
  };

  shouldComponentUpdate(nextProps, nextState) {
    return !isEqual(this.props.hhgInformation, nextProps.hhgInformation) ||
      this.props.isSubmitting !== nextProps.isSubmitting ||
      !isEqual(this.state, nextState);
  }

  renderHeader() {
    const {
      classes,
      hhgInformation,
      handleChange,
      handleBlur,
    } = this.props;

    return (
      <div className="row align-center">
        <Typography variant="h3">HHG</Typography>
        <Field
          component={Checkbox}
          className={classes.headerToggle}
          name="hhgInformation.isHhgServiceInitiated"
          value={hhgInformation.isHhgServiceInitiated}
          onChange={(event) => {
            // this value is the current state, not what it will change to if we allow it
            if (event.target.value === 'checked' && hhgInformation.isPopulated()) {
              this.setState({ showConfirmation: true });
            } else {
              handleChange(event);
            }
          }}
          onBlur={handleBlur}
          label="Initiate Service"
        />
      </div>
    );
  }

  handleDisableService = () => {
    this.props.setFieldValue('hhgInformation', new HhgInformation(), false);
    this.setState({ showConfirmation: false });
  };

  renderVehiclesCard() {
    const {
      hhgInformation,
      handleChange,
      handleBlur,
      isSubmitting,
    } = this.props;

    return (
      <FieldArray
        name="hhgInformation.vehicles"
        render={(arrayHelpers) => (
          <Paper classes={{ root: 'card-root' }}>
            <Grid container item alignItems="center" justify="space-between">
              <FormLabel>Vehicle(s) Make</FormLabel>
              <Button disabled={isSubmitting} onClick={addVehicle(arrayHelpers)}>Add</Button>
            </Grid>
            {hhgInformation.vehicles.map((vehicle, index) => (
              <div className="input-row" key={index}>
                <div>
                  <Field
                    component={TextField}
                    name={`hhgInformation.vehicles.${index}.year`}
                    label="Year"
                    value={vehicle.year}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    numeric
                    inputProps={{
                      maxLength: 4,
                    }}
                    includeThousandsSeparator={false}
                  />
                  <Field
                    component={TextField}
                    name={`hhgInformation.vehicles.${index}.make`}
                    label="Make"
                    value={vehicle.make}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <Field
                    component={TextField}
                    name={`hhgInformation.vehicles.${index}.model`}
                    label="Model"
                    value={vehicle.model}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <Field
                    component={Checkbox}
                    name={`hhgInformation.vehicles.${index}.isInWorkingOrder`}
                    value={vehicle.isInWorkingOrder}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    label="In Working Order?"
                    alignCenter
                  />
                </div>
                <AutosaveDeleteButton disabled={isSubmitting} onClick={() => arrayHelpers.remove(index)} />
              </div>
            ))}
          </Paper>
        )}
      />
    );
  }

  render() {
    Log.trace('RENDER', 'HhgInformationForm');
    const {
      hhgInformation,
      handleChange,
      handleBlur,
    } = this.props;
    const { showConfirmation } = this.state;

    return (
      <div className="form-group">
        <ExpansionPanel expanded={hhgInformation.isHhgServiceInitiated}>
          <ExpansionPanelSummary>{this.renderHeader()}</ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <Grid container>
              <Grid container item spacing={1}>
                <Grid item xs={12} md={9}>
                  <Field
                    component={TextField}
                    name="hhgInformation.largeBulkyItemsRecreationalVehicles"
                    label="Large or Bulky Items/Recreational Equipment"
                    value={hhgInformation.largeBulkyItemsRecreationalVehicles}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid container item spacing={1}>
                <Grid item xs={12} md={9}>
                  {this.renderVehiclesCard()}
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <div className="my-2">
                  <Divider variant="fullWidth" className="full-width" />
                </div>
                <Typography variant="h4">Storage Units</Typography>
              </Grid>
              <Grid container item spacing={1}>
                <Grid item xs={12} md={3}>
                  <Field
                    component={ToggleSelect}
                    name="hhgInformation.hasStorageUnits"
                    value={hhgInformation.hasStorageUnits}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    options={YES_NO_OPTIONS}
                    label="Has Storage Units"
                    alignCenter
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Field
                    component={TextField}
                    name="hhgInformation.storageUnitContents"
                    label="Contents"
                    value={hhgInformation.storageUnitContents}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    fullWidth
                    disabled={!hhgInformation.hasStorageUnits}
                  />
                </Grid>
              </Grid>
              <Grid container item spacing={1}>
                <Grid item xs={12} md={3}>
                  <Field
                    component={TextField}
                    name="hhgInformation.storageUnitFacilityName"
                    label="Facility Name"
                    value={hhgInformation.storageUnitFacilityName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    fullWidth
                    disabled={!hhgInformation.hasStorageUnits}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <Field
                    component={TextField}
                    name="hhgInformation.storageUnitAddress"
                    label="Facility Address"
                    value={hhgInformation.storageUnitAddress}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    fullWidth
                    disabled={!hhgInformation.hasStorageUnits}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <Field
                    component={TextField}
                    name="hhgInformation.storageUnitCityStateZip"
                    label="Facility City/State/Zip"
                    value={hhgInformation.storageUnitCityStateZip}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    fullWidth
                    disabled={!hhgInformation.hasStorageUnits}
                  />
                </Grid>
              </Grid>

            </Grid>
          </ExpansionPanelDetails>
        </ExpansionPanel>
        <ConfirmationDialog
          open={showConfirmation}
          title="Disable Service?"
          confirmLabel="Yes"
          cancelLabel="No"
          onConfirm={this.handleDisableService}
          onCancel={() => this.setState({ showConfirmation: false })}
        >
          <Typography>
            If you disable this service, you will lose all data entered in this section. Do you want to proceed?
          </Typography>
        </ConfirmationDialog>
      </div>
    );
  }
}

HhgInformationForm.propTypes = {
  classes: PropTypes.object.isRequired,
  hhgInformation: PropTypes.object.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  showToast: PropTypes.func.isRequired,
  metadata: PropTypes.object.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  setFieldValue: PropTypes.func.isRequired,
};

export default compose(
  withStyles(styles),
  connect(null, {
    showToast,
  }),
)(HhgInformationForm);
