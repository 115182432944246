import { keyBy } from 'lodash';
import dot from 'dot-object';

export const remapMetadata = (metadata) => {
  return [
    'basementFinishTypes',
    'commentRegardingTypes',
    'commentServiceTypes',
    'contactInfoOwnerTypes',
    'contactPreferenceTypes',
    'contactTypes',
    'emailTypes',
    'familiarWithAreaTypes',
    'hoaPaymentScheduleTypes',
    'homesaleTypes',
    'houseHeatTypes',
    'houseInspectionTypes',
    'housePoolTypes',
    'housePropertyTypes',
    'houseRoofStyleTypes',
    'houseSewageTypes',
    'houseSidingTypes',
    'houseStyleTypes',
    'houseWaterSourceTypes',
    'maritalStatuses',
    'moveReasons',
    'moveTypes',
    'petTypes',
    'phoneTypes',
    'preferenceTypes',
    'propertyCreditBalanceTypes',
    'reloPolicyTypes',
    'taxFilingStatuses',
    'solarPanelOwnTypes',
  ].reduce((remappedData, metadataType) => {
    if (metadata[metadataType]) {
      metadata[metadataType] = metadata[metadataType].filter((md) => md.isActive);
    }
    remappedData[metadataType] = keyBy(metadata[metadataType], 'id');
    return remappedData;
  }, {});
};

const parseKey = (key, removePrefix) => {
  const parts = key.split('.').map((part) => part.charAt(0).toLowerCase() + part.substring(1));
  return (parts.length > 1 && parts[0] === removePrefix ? parts.splice(1): parts).join('.');
};

export const remapValidationResults = (response) => {
  return response.reduce((results, section) => {
    return {
      ...results,
      [section.name]: section.result.errors.reduce((errors, error) => {
        const key = parseKey(error.propertyName, section.name);
        return {
          ...errors,
          ...dot.object({
            [key]: error.errorMessage,
          }),
        };
      }, {}),
    };
  }, {});
};
