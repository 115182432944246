import { ChronoUnit, DateTimeFormatter, LocalDate, LocalDateTime, ZonedDateTime } from 'js-joda';
import { Locale } from '@js-joda/locale_en-us';
import { isEmpty, isObject, keys as lodashKeys, values } from 'lodash';

import Log from './log';

const DATE_FORMATTER = DateTimeFormatter.ofPattern('M/d/yyyy').withLocale(Locale.US);
const LONG_DATE_FORMATTER = DateTimeFormatter.ofPattern('MMMM d, yyyy').withLocale(Locale.US);
const INPUT_DATE_FORMATTER = DateTimeFormatter.ofPattern('yyyy-MM-dd').withLocale(Locale.US);

const TRC_APP_CLIENT_ID = 'TRC_APP_CLIENT_ID';
const TRC_APP_CLIENT_NAME = 'TRC_APP_CLIENT_NAME';
const TRC_APP_SELECTED_DIVISIONS = 'TRC_APP_SELECTED_DIVISIONS';

const MILES_PER_METER = 0.0006213712;
const YEARS_PAST_THRESHOLD = 5;

export const isNotDefined = (value) => value === undefined || value === null;
export const hasNonNullValue = (value) => value !== undefined && value !== null;

export const formatForWire = (data) => {
  return lodashKeys(data).reduce((acc, key) => {
    let value = data[key];

    if (typeof value === 'string') {
      value = value.trim();
      if (value === '') {
        value = null;
      }
    }

    acc[key] = value;
    return acc;
  }, {});
};

export const formatDistance = (meters) => {
  return `${Math.round(meters * MILES_PER_METER)} mi`;
};

export const formatDate = (date) => {
  return date ? ZonedDateTime.parse(date).format(DATE_FORMATTER): '';
};

export const formatLocalDate = (date) => {
  if (typeof date === 'string') {
    return date ? LocalDate.parse(date).format(DATE_FORMATTER): '';
  }
  return date ? date.format(DATE_FORMATTER): '';
};

export const formatLocalDateLong = (date) => {
  return date ? LocalDate.parse(date).format(LONG_DATE_FORMATTER): '';
};

export const formatAddress = ({ city, state, country }) => {
  return [city, state, country].filter((component) => {
    return !!component;
  }).join(', ');
};

export const formatZonedDateForInput = (date) => {
  try {
    return date ? ZonedDateTime.parse(date).format(INPUT_DATE_FORMATTER): '';
  } catch (err) {
    Log.error(`Failed to parse zoned date ${date} ${err}`);
  }
};

export const formatDateForInput = (date) => {
  try {
    if (!date) {
      return '';
    } else if (typeof date === 'string') {
      return LocalDateTime.parse(date).format(INPUT_DATE_FORMATTER);
    } else {
      return date.format(INPUT_DATE_FORMATTER);
    }
  } catch (err) {
    Log.error(`Failed to parse date ${date} ${err}`);
  }
};

export const getNow = () => {
  return LocalDate.now().format(DATE_FORMATTER);
};

export const getToday = () => {
  return LocalDate.now().format(INPUT_DATE_FORMATTER);
};

export const isToday = (date) => {
  // there's probably a better way to do this
  return ZonedDateTime.now().format(DATE_FORMATTER) === formatDate(date);
};

export const isRecent = (date) => {
  try {
    if (!date) {
      return false;
    }

    const parsedDate = typeof date === 'string' ? LocalDate.parse(date) : date;
    return parsedDate.until(LocalDate.now(), ChronoUnit.YEARS) <= YEARS_PAST_THRESHOLD;
  } catch (err) {
    return false;
  }
};

export const jsonToQueryString = (json) => {
  return `?${
    Object.keys(json).map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(json[key])}`).join('&')}`;
};

export const getSelectedClientInformation = () => {
  let clientId = window.localStorage.getItem(TRC_APP_CLIENT_ID) || null;
  let clientName = window.localStorage.getItem(TRC_APP_CLIENT_NAME) || null;

  return clientId === 'null' || clientId === null ?
    { id: null, description: null } :
    { id: clientId, description: clientName };
};

export const getSelectedDivisions = () => {
  let divisions = window.localStorage.getItem(TRC_APP_SELECTED_DIVISIONS) || null;
  return divisions === 'null' || divisions === null ? [] : divisions.split(',');
};

export const YES_NO_OPTIONS = Object.freeze([
  { id: true, description: 'Yes' },
  { id: false, description: 'No' },
]);

export const IS_RENTED_OPTIONS = Object.freeze([
  { id: false, description: 'Own' },
  { id: true, description: 'Rent' },
]);

export const metadataSort = (a, b) => String(a.description).localeCompare(String(b.description));

export const calculateEndDate = (startDate, duration) => {
  let endDate = null;
  const parsedDuration = parseInt(duration, 10);
  if (startDate && !isNaN(parsedDuration) && parsedDuration > 0) {
    const parsedStartDate = typeof startDate === 'string' ? LocalDate.parse(startDate) : startDate;
    endDate = parsedStartDate.plusDays(parsedDuration - 1);
  }
  return endDate;
};

export const calculateDuration = (startDate, endDate) => {
  if (!startDate || !endDate) {
    return '';
  }

  const parsedStartDate = typeof startDate === 'string' ? LocalDate.parse(startDate) : startDate;
  const parsedEndDate = typeof endDate === 'string' ? LocalDate.parse(endDate) : endDate;

  return parsedStartDate.until(parsedEndDate, ChronoUnit.DAYS) + 1;
};

export const defaultEndDate = (startDate, endDate, endDateFieldPath, setFieldValue) => {
  let newEndDate = endDate;
  if (!endDate || new Date(endDate) < new Date(startDate)) {
    newEndDate = startDate;
  }
  setFieldValue(endDateFieldPath, newEndDate);
  return newEndDate;
};

export const isType = (type, id, metadata) => {
  const found = values(metadata).find((instance) => {
    return String(instance.description).toUpperCase() === type;
  });
  return found && String(id) === String(found.id);
};

export const isUnknown = (id, metadata) => {
  return isType('UNKNOWN', id, metadata);
};

export const parseNumbers = (data, keys) => {
  const parsed = { ...data };
  (keys || Object.keys(data)).forEach((key) => {
    let strValue = String(data[key]).replace(/[^\d.]/g, '').replace(/,/gi, '');
    strValue = isNaN(strValue) ? '' : strValue; //String(data[key]).replace(/\D/g, '');
    const parsedValue = parseFloat(strValue, 10);
    parsed[key] = isNaN(parsedValue) ? null: parsedValue;
  });
  return parsed;
};

export const wasCreatedToday = (instance) => {
  return !instance.id || isToday(instance.created);
};

export const getMapUrl = (departureLocation, destinationLocation) => {
  const departureAddress = departureLocation.formattedAddress;
  const destinationAddress = destinationLocation.formattedAddress;
  if (departureAddress && destinationAddress) {
    return `https://www.google.com/maps/dir/${encodeURIComponent(departureAddress)}/${encodeURIComponent(destinationAddress)}`;
  }
  return null;
};

export const isEmptyDeep = (obj) => {
  if (isObject(obj)) {
    if (Object.keys(obj).length === 0)
      return true;
    return Object.keys(obj).every((k) => isEmptyDeep(obj[k]) );
  }
  return isEmpty(obj);
};
