import { Button, Grid, Typography } from '@material-ui/core';

import { Field } from 'formik';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import { TextField } from 'modules/intake/form';

import { defaultEndDate } from 'utilities/common';
import ColorIndicator from './colorIndicator.component';
import Colors from './colors';

class TemporaryLivingForm extends PureComponent {
  render() {
    const { temporaryLiving, isFormEnabled, onEnableService, handleBlur, handleChange, setFieldValue, rootPath } = this.props;

    return (
      <div className="form-group">
        <Grid container alignItems="center">
          <Grid item xs={6}>
            <Typography gutterBottom variant="h3" className={isFormEnabled ? '':  'disabled'}>Temp Living</Typography>
          </Grid>
          <Grid item md={6}>
            <Button variant="outlined"
              color="secondary"
              onClick={onEnableService}
            >{
                isFormEnabled ? 'Disable Service' : 'Enable Service'
              }</Button>
          </Grid>
        </Grid>
        {
          isFormEnabled &&
            <div className="row">
              <Field
                component={TextField}
                name={`${rootPath}.temporaryLiving.estimatedStartDate`}
                label="Estimated Start Date"
                value={temporaryLiving.estimatedStartDate}
                onChange={(e) => {
                  defaultEndDate(e.target.value, temporaryLiving.estimatedEndDate, `${rootPath}.temporaryLiving.estimatedEndDate`, setFieldValue);
                  handleChange(e);
                }}
                onBlur={handleBlur}
                type="date"
              />
              <Field
                component={TextField}
                name={`${rootPath}.temporaryLiving.estimatedEndDate`}
                label="Estimated End Date"
                value={temporaryLiving.estimatedEndDate}
                onChange={handleChange}
                onBlur={handleBlur}
                type="date"
              />
              <ColorIndicator color={Colors.temporaryLiving} />
            </div>
        }
      </div>
    );
  }
}

TemporaryLivingForm.propTypes = {
  temporaryLiving: PropTypes.object.isRequired,
  handleBlur: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  rootPath: PropTypes.string.isRequired,
  onEnableService: PropTypes.func.isRequired,
  isFormEnabled: PropTypes.bool.isRequired,
  setFieldValue: PropTypes.func.isRequired,
};

export default TemporaryLivingForm;
