export const GET_INTAKE_FORM_METADATA_REQUEST = 'GET_INTAKE_FORM_METADATA_REQUEST';
export const GET_INTAKE_FORM_METADATA_SUCCESS = 'GET_INTAKE_FORM_METADATA_SUCCESS';
export const GET_INTAKE_FORM_METADATA_FAILURE = 'GET_INTAKE_FORM_METADATA_FAILURE';

export const GET_INTAKE_RECORD_REQUEST = 'GET_INTAKE_RECORD_REQUEST';
export const GET_INTAKE_RECORD_SUCCESS = 'GET_INTAKE_RECORD_SUCCESS';
export const GET_INTAKE_RECORD_FAILURE = 'GET_INTAKE_RECORD_FAILURE';

export const UPDATE_INTAKE_RECORD_REQUEST = 'UPDATE_INTAKE_RECORD_REQUEST';
export const UPDATE_INTAKE_RECORD_SUCCESS = 'UPDATE_INTAKE_RECORD_SUCCESS';
export const UPDATE_INTAKE_RECORD_FAILURE = 'UPDATE_INTAKE_RECORD_FAILURE';

export const SET_INTAKE_RECORD_URGENCY_REQUEST = 'SET_INTAKE_RECORD_URGENCY_REQUEST';
export const SET_INTAKE_RECORD_URGENCY_SUCCESS = 'SET_INTAKE_RECORD_URGENCY_SUCCESS';
export const SET_INTAKE_RECORD_URGENCY_FAILURE = 'SET_INTAKE_RECORD_URGENCY_FAILURE';

export const GET_INTAKE_SUMMARIES_REQUEST = 'GET_INTAKE_SUMMARIES_REQUEST';
export const GET_INTAKE_SUMMARIES_SUCCESS = 'GET_INTAKE_SUMMARIES_SUCCESS';
export const GET_INTAKE_SUMMARIES_FAILURE = 'GET_INTAKE_SUMMARIES_FAILURE';

export const UPDATE_INTAKE_SUMMARY_REQUEST = 'UPDATE_INTAKE_SUMMARY_REQUEST';
export const UPDATE_INTAKE_SUMMARY_SUCCESS = 'UPDATE_INTAKE_SUMMARY_SUCCESS';
export const UPDATE_INTAKE_SUMMARY_FAILURE = 'UPDATE_INTAKE_SUMMARY_FAILURE';

export const ADD_NOTE_REQUEST = 'ADD_NOTE_REQUEST';
export const ADD_NOTE_SUCCESS = 'ADD_NOTE_SUCCESS';
export const ADD_NOTE_FAILURE = 'ADD_NOTE_FAILURE';

export const ADD_COMMENT_REQUEST = 'ADD_COMMENT_REQUEST';
export const ADD_COMMENT_SUCCESS = 'ADD_COMMENT_SUCCESS';
export const ADD_COMMENT_FAILURE = 'ADD_COMMENT_FAILURE';

export const ASSIGN_INTAKE_RECORD_REQUEST = 'ASSIGN_INTAKE_RECORD_REQUEST';
export const ASSIGN_INTAKE_RECORD_SUCCESS = 'ASSIGN_INTAKE_RECORD_SUCCESS';
export const ASSIGN_INTAKE_RECORD_FAILURE = 'ASSIGN_INTAKE_RECORD_FAILURE';

export const DELETE_INTAKE_RECORD_REQUEST = 'DELETE_INTAKE_RECORD_REQUEST';
export const DELETE_INTAKE_RECORD_SUCCESS = 'DELETE_INTAKE_RECORD_SUCCESS';
export const DELETE_INTAKE_RECORD_FAILURE = 'DELETE_INTAKE_RECORD_FAILURE';

export const VALIDATE_INTAKE_RECORD_REQUEST = 'VALIDATE_INTAKE_RECORD_REQUEST';
export const VALIDATE_INTAKE_RECORD_SUCCESS = 'VALIDATE_INTAKE_RECORD_SUCCESS';
export const VALIDATE_INTAKE_RECORD_FAILURE = 'VALIDATE_INTAKE_RECORD_FAILURE';

export const SUBMIT_INTAKE_RECORD_REQUEST = 'SUBMIT_INTAKE_RECORD_REQUEST';
export const SUBMIT_INTAKE_RECORD_SUCCESS = 'SUBMIT_INTAKE_RECORD_SUCCESS';
export const SUBMIT_INTAKE_RECORD_FAILURE = 'SUBMIT_INTAKE_RECORD_FAILURE';

export const SET_FOCUSED_TAB = 'SET_FOCUSED_TAB';
export const SET_FOCUSED_GROUP = 'SET_FOCUSED_GROUP';
export const CLEAR_FOCUSED_GROUP = 'CLEAR_FOCUSED_GROUP';

export const IMPORT_INTAKE_RECORD_REQUEST = 'IMPORT_INTAKE_RECORD_REQUEST';
export const IMPORT_INTAKE_RECORD_SUCCESS = 'IMPORT_INTAKE_RECORD_SUCCESS';
export const IMPORT_INTAKE_RECORD_FAILURE = 'IMPORT_INTAKE_RECORD_FAILURE';

export const CLEAR_CACHED_INTAKE_RECORD = 'CLEAR_CACHED_INTAKE_RECORD';
export const CLEAR_INTAKE_RECORD_VALIDATION = 'CLEAR_INTAKE_RECORD_VALIDATION';

export const SET_VALIDATION_MODE = 'SET_VALIDATION_MODE';
