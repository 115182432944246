import { array, object } from 'yup';
import { get, without } from 'lodash';

import { formatForWire, isNotDefined } from 'utilities/common';
import { getDistance } from 'utilities/googleMapsClient';
import AuthorizationComment from './authorizationComment';
import AuthorizationInformation from './authorizationInformation';
import Benefits from './benefits';
import Contact from './contact';
import DeparturePropertyInformation from './departurePropertyInformation';
import DestinationInformation from './destinationInformation';
import FamilyInformation from './familyInformation';
import HhgInformation from './hhgInformation';
import Location from './location';
import Note from './note';
import PrimaryInformation from './primaryInformation';
import TemporaryLivingInformation from './temporaryLivingInformation';

export default class IntakeRecord {
  static schema = object().shape({
    primaryInformation: PrimaryInformation.schema,
    authorizationInformation: AuthorizationInformation.schema,
    familyInformation: FamilyInformation.schema,
    departureProperty: DeparturePropertyInformation.schema,
    hhgInformation: HhgInformation.schema,
    temporaryLiving: TemporaryLivingInformation.schema,
    newDestination: DestinationInformation.schema,
    notes: array().of(Note.schema),
    benefits: Benefits.schema,
    authorizationComments: array().of(AuthorizationComment.schema),
  });

  constructor(wireData, metadata) {
    const json = wireData || {};
    const {
      id,
      departureProperty,
      contacts,
      contactPreferenceTypeId,
      destinationCity,
      destinationStateCode,
      destinationCountryCode,
      relocationDistance,
      mtAuthorizationId,
      client,
      moveTypeId,
      vip,
      newJobTitle,
      authorizationDate,
      newJobStartDate,
      repaymentAgreementRequired,
      repaymentAgreementSentDate,
      repaymentAgreementReceivedDate,
      relocationPolicyCodeId,
      intakeRecordStatusId,
      pets,
      phoneNumbers,
      emailAddresses,
      isSellingDepartureProperty,
      isEligibleForHomesale,
      isHhgServiceInitiated,
      isTemporaryLivingServiceInitiated,
      isDestinationServiceInitiated,
      notes,
      authorizationComments,
      maritalStatusId,
      taxFilingStatusId,
      selfServicePolicyId,
      selfServicePolicyBudget,
    } = json;

    const mappedContacts = contacts.map((contact) => new Contact(contact, metadata.contactTypes));
    const transferee = mappedContacts.find((contact) => contact.isTransferee());
    const nonTransfereeContacts = without(mappedContacts, transferee);

    this.id = id;

    this.primaryInformation = new PrimaryInformation({
      transferee,
      departureCity: departureProperty.city,
      departureStateCode: departureProperty.stateCode,
      departureCountryCode: departureProperty.countryCode,
      destinationCity,
      destinationStateCode,
      destinationCountryCode,
      relocationDistance,
      relocationPolicyCodeId,
      intakeRecordStatusId,
      relocationPolicyCodes: client.relocationPolicyCodes,
      newJobStartDate,
    });

    this.authorizationInformation = new AuthorizationInformation({
      mtAuthorizationId,
      client,
      moveTypeId,
      authorizationDate,
      vip,
      newJobTitle,
      repaymentAgreementRequired,
      repaymentAgreementSentDate,
      repaymentAgreementReceivedDate,
      selfServicePolicyId,
      selfServicePolicyBudget,
    });

    this.familyInformation = new FamilyInformation({
      maritalStatusId,
      taxFilingStatusId,
      contacts: nonTransfereeContacts,
      pets,
      phoneNumbers,
      emailAddresses,
      contactPreferenceTypeId,
    });

    this.departureProperty = new DeparturePropertyInformation(
      {
        isSellingDepartureProperty,
        isEligibleForHomesale,
        intakeRecordId: id,
        ...json.departureProperty,
      },
      transferee,
      nonTransfereeContacts,
      metadata,
    );

    this.hhgInformation = new HhgInformation({
      isHhgServiceInitiated,
      ...json.intakeHhgServiceDetail,
    });

    this.temporaryLiving = new TemporaryLivingInformation(
      {
        isTemporaryLivingServiceInitiated,
        ...json.temporaryLivingInfo,
      },
      nonTransfereeContacts,
      this.familyInformation.pets,
    );

    this.newDestination = new DestinationInformation({
      isDestinationServiceInitiated,
      ...json.destinationServiceInfo,
    });

    const temporaryLivingInfo = get(json, 'temporaryLivingInfo') || {};
    this.benefits = new Benefits(
      {
        relocationAllowance: json.relocationAllowance,
        candidateInternInfo: json.candidateInternInfo,
        homesaleProcess: json.homesaleProcess,
        hhgBenefitsTimeline: json.hhgBenefitsTimeline,
        temporaryLivingInfo: {
          estimatedStartDate: temporaryLivingInfo.estimatedStartDate,
          estimatedEndDate: temporaryLivingInfo.estimatedEndDate,
        },
        houseHunting: json.houseHunting,
        finalMove: json.finalMove,
        miscTimelineEntries: json.miscTimelineEntries,
        spousalAssistance: json.spousalAssistance,
      },
      this.temporaryLiving,
    );

    this.notes = (notes || []).map((instance) => new Note(instance));

    this.authorizationComments = (authorizationComments || []).map(
      (instance) => new AuthorizationComment(instance, metadata.commentServiceTypes, metadata.commentRegardingTypes),
    );
  }

  static async fromWireData(wireData, metadata) {
    const intakeRecord = new IntakeRecord(wireData, metadata);
    if (isNotDefined(intakeRecord.primaryInformation.relocationDistance)) {
      await intakeRecord.primaryInformation.calculateRelocationDistance();
    }
    return intakeRecord;
  }

  static generateErrors(errorData) {
    if (!errorData) {
      return {};
    }

    const {
      primaryInformation,
      authorizationInformation,
      familyInformation,
      departureProperty,
      hhgInformation,
      temporaryLiving,
      newDestination,
      relocationAllowance,
      candidateInternInfo,
      houseHunting,
      homesaleProcess,
      finalMove,
      temporaryLivingTimeline,
      householdGoods,
      spousalAssistance,
    } = errorData;

    return {
      primaryInformation: PrimaryInformation.generateErrors(primaryInformation),
      authorizationInformation: AuthorizationInformation.generateErrors(authorizationInformation),
      familyInformation: FamilyInformation.generateErrors(familyInformation),
      departureProperty: DeparturePropertyInformation.generateErrors(departureProperty),
      hhgInformation: HhgInformation.generateErrors(hhgInformation),
      temporaryLiving: TemporaryLivingInformation.generateErrors(temporaryLiving),
      newDestination: DestinationInformation.generateErrors(newDestination),
      benefits: Benefits.generateErrors({
        relocationAllowance,
        candidateInternInfo,
        houseHunting,
        homesaleProcess,
        finalMove,
        temporaryLivingTimeline,
        householdGoods,
        spousalAssistance,
      }),
    };
  }

  async toWireData() {

    let syncedRelocationDistance = null;
    try {
      const { city, stateCode, countryCode } = this.departureProperty.address;
      this.primaryInformation.departureCity = city;
      this.primaryInformation.departureStateCode = stateCode;
      this.primaryInformation.departureCountryCode = countryCode;

      const { value } = await getDistance(
        new Location({ city, state: stateCode, country: countryCode }),
        this.primaryInformation.destinationLocation,
      );
      syncedRelocationDistance = value;
    } catch (err) {
      // eat it
    }

    const {
      transferee,
      destinationLocation: { city: destinationCity, state: destinationStateCode, country: destinationCountryCode },
      relocationPolicyCodeId,
      newJobStartDate,
    } = this.primaryInformation.toWireData();

    const {
      maritalStatusId,
      taxFilingStatusId,
      contacts,
      pets,
      phoneNumbers,
      emailAddresses,
      contactPreferenceTypeId,
    } = this.familyInformation.toWireData();

    const { isSellingDepartureProperty, isEligibleForHomesale, ...departurePropertyRest } = this.departureProperty.toWireData();

    const { isHhgServiceInitiated, ...hhgInformationRest } = this.hhgInformation.toWireData();

    const { isTemporaryLivingServiceInitiated, ...temporaryLivingRest } = this.temporaryLiving.toWireData(contacts, pets);

    const { isDestinationServiceInitiated, ...newDestinationRest } = this.newDestination.toWireData();

    const {
      relocationAllowance,
      candidateInternInfo,
      homesaleProcess,
      hhgBenefitsTimeline,
      temporaryLiving: temporaryLivingBenefits,
      houseHunting,
      finalMove,
      miscellaneous: { miscTimelineEntries },
      spousalAssistance,
    } = this.benefits.toWireData();

    return formatForWire({
      id: this.id,
      contacts: [transferee, ...contacts],
      ...this.authorizationInformation.toWireData(),
      newJobStartDate,
      departureCity : this.primaryInformation.departureCity,
      departureStateCode : this.primaryInformation.departureStateCode,
      departureCountryCode : this.primaryInformation.departureCountryCode,
      destinationCity,
      destinationStateCode,
      destinationCountryCode,
      relocationDistance: departurePropertyRest.city && destinationCity ? syncedRelocationDistance : null,
      relocationPolicyCodeId,
      maritalStatusId,
      taxFilingStatusId,
      pets,
      phoneNumbers,
      emailAddresses,
      contactPreferenceTypeId,
      isSellingDepartureProperty,
      isEligibleForHomesale,
      departureProperty: departurePropertyRest,
      isHhgServiceInitiated,
      intakeHhgServiceDetail: hhgInformationRest,
      isTemporaryLivingServiceInitiated,
      temporaryLivingInfo: {
        ...temporaryLivingRest,
        ...temporaryLivingBenefits,
      },
      isDestinationServiceInitiated,
      destinationServiceInfo: newDestinationRest,
      notes: this.notes.map((instance) => instance.toWireData()),
      relocationAllowance,
      homesaleProcess,
      hhgBenefitsTimeline,
      houseHunting,
      finalMove,
      miscTimelineEntries,
      spousalAssistance,
      authorizationComments: this.authorizationComments.map((instance) => instance.toWireData()),
      candidateInternInfo,
    });
  }
}
