import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import * as firebase from 'firebase';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import ReactRouterPropTypes from 'react-router-prop-types';
import classNames from 'classnames';

import {
  AppBar,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Tooltip,
  Typography,
  withStyles,
} from '@material-ui/core';
import { ChevronLeft, ChevronRight, Menu } from '@material-ui/icons';

import { DRAWER_WIDTH_CLOSED, DRAWER_WIDTH_CLOSED_XS, DRAWER_WIDTH_OPEN } from 'styles/theme';
import { GET_USER_PROFILE_SUCCESS } from 'modules/auth/types';
import { getUserProfile } from 'modules/auth/auth.actions';
import IntakeRecordDetailsView from 'modules/intake/details/IntakeRecordDetailsView.container';
import IntakeRecordsView from 'modules/intake/summary/intakeRecords.container';
import PasswordResetDialog from 'modules/auth/passwordResetDialog.component';
import auth from 'auth/auth';
import visitor from 'utilities/visitor';

import { GET_FIREBASE_TOKEN_SUCCESS } from 'index.types';
import { GET_RELOCATION_CONSULTANTS_FAILURE } from 'modules/common/types';
import { IMPORT_INTAKE_RECORD_SUCCESS } from 'modules/intake/types';
import { TOAST_MESSAGE_SEVERITY_ERROR } from './types';
import { TOAST_MESSAGE_SEVERITY_SUCCESS } from 'modules/layout/types';
import { cachedWithRouteOnEnter } from 'modules/common/withRouteOnEnter.component';
import { getApiVersion, hideSideDrawer, showSideDrawer, showToast } from './layout.actions';
import { getFirebaseToken } from 'index.actions';
import { getIntakeSummaries, importIntakeRecord } from 'modules/intake/intake.actions';
import { getRelocationConsultants } from 'modules/common/common.actions';
import BenefitForm from 'modules/benefit/benefitForm.component';
import BenefitsContainer from 'modules/benefit/benefits.container';
import ImportIntakeRecordDialog from 'modules/intake/importIntakeRecordDialog.component';
import SelfServiceContainer from 'modules/selfService/selfService.container';
import SelfServiceDetailsContainer from 'modules/selfService/details/selfServiceDetails.container';
import withWidth from '@material-ui/core/withWidth';

const styles = (theme) => ({
  root: {
    display: 'flex',
    height: '100%',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: DRAWER_WIDTH_OPEN,
    width: `calc(100% - ${DRAWER_WIDTH_OPEN}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: DRAWER_WIDTH_OPEN,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: DRAWER_WIDTH_OPEN,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: DRAWER_WIDTH_CLOSED_XS,
    [theme.breakpoints.up('sm')]: {
      width: DRAWER_WIDTH_CLOSED,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  menuIconRoot: {
    margin: 0,
    justifyContent: 'center',
    marginRight: theme.spacing(2),
    minWidth: 'unset',
    width: theme.spacing(3) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(5) + 1,
    },
  },
  menuIconSelected: {
    color: theme.palette.primary.main,
  },
  menuList: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
});

const SESSION_RENEWAL_INTERVAL = 3600000; // one hour

class AuthenticatedRoutes extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      showPasswordReset: false,
      passwordResetError: null,
      isSubmittingPasswordReset: false,
      showImport: false,
      importError: null,
    };
    this.renewSessionHandle = null;
  }

  async componentDidMount() {
    // TODO handle failure case
    let { type, response } = await this.props.getUserProfile();
    if (type === GET_USER_PROFILE_SUCCESS) {
      visitor.set('uid', response.email);
    }
    await this.props.getApiVersion();
    await this.authenticateWithFirebase();
    this.initSessionTimers();
  }

  componentWillUnmount() {
    document.removeEventListener('visibilitychange', this.renewSessionWhenVisible, false);
    clearInterval(this.renewSessionHandle);
  }

  authenticateWithFirebase = async () => {
    const tokenResult = await this.props.getFirebaseToken();
    if (tokenResult.type === GET_FIREBASE_TOKEN_SUCCESS) {
      await firebase.auth().signInWithCustomToken(tokenResult.response);
    }
  };

  initSessionTimers = () => {
    document.addEventListener('visibilitychange', this.renewSessionWhenVisible, false);
    this.renewSessionHandle = setInterval(() => {
      this.renewSession();
    }, SESSION_RENEWAL_INTERVAL);
  };

  renewSessionWhenVisible = ({ target }) => {
    if (target.visibilityState === 'visible') {
      this.renewSession();
    }
  };

  renewSession = async () => {
    const { history } = this.props;
    const wasRenewed = await auth.renewSession();
    if (!wasRenewed) {
      history.replace('/login', {
        from: { pathname: history.pathname },
      });
    }
  };

  navigate = (path) => {
    this.props.history.push(path);
  };

  handlePasswordResetRequest = async () => {
    this.setState({ passwordResetError: null, isSubmittingPasswordReset: true });
    try {
      const { email } = this.props.profile;
      await auth.requestPasswordReset(email);
      this.setState({ isSubmittingPasswordReset: false, showPasswordReset: false });
      this.props.showToast('Success! Please check your email for reset instructions');
    } catch (err) {
      this.setState({
        isSubmittingPasswordReset: false,
        passwordResetError: 'Something went wrong, please try again',
      });
    }
  };

  handleImportRequest = async (authId) => {
    this.setState({ importError: null });

    const { type } = await this.props.importIntakeRecord(authId);
    if (type === IMPORT_INTAKE_RECORD_SUCCESS) {
      this.setState({ showImport: false });
      this.props.showToast('Successfully imported auth file!', { severity: TOAST_MESSAGE_SEVERITY_SUCCESS });

      if (auth.userHasRole('admin')) {
        let getConsultantsAction = await this.props.getRelocationConsultants();
        if (getConsultantsAction.type === GET_RELOCATION_CONSULTANTS_FAILURE) {
          this.props.showToast('Failed to retrieve data, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        }
      }

      this.props.getIntakeSummaries();
    } else {
      this.setState({ importError: 'Failed to import file, please check auth ID and try again' });
    }
  };

  onRouteEnter = () => {
    const authorized = auth.isPathAuthorized(this.props.location.pathname);
    if (!authorized) {
      this.props.history.push('/');
    }
  };

  navigationItems = [
    {
      title: 'Transferees',
      route: '/intakeRecords',
      icon: ['far', 'id-badge'],
    },
    {
      title: 'Benefits',
      route: '/benefits',
      requiredPermission: 'selfService:write:benefits',
      icon: ['fas', 'ballot'],
    },
    {
      title: 'Self Service',
      route: '/selfService',
      requiredPermission: 'selfService:read:auths',
      icon: ['fas', 'concierge-bell'],
    },
  ];

  render() {
    const { classes, theme, title, shouldShowSideDrawer, profile, isImportingRecord, apiVersion, location } = this.props;
    const { showPasswordReset, passwordResetError, isSubmittingPasswordReset, showImport, importError } = this.state;

    const screenIsXl = this.props.width === 'xl';
    const sideDrawerAvailable = screenIsXl && shouldShowSideDrawer;

    return (
      <div className={classes.root}>
        <AppBar
          position="fixed"
          className={classNames(classes.appBar, {
            [classes.appBarShift]: sideDrawerAvailable,
          })}
        >
          <Toolbar disableGutters={!sideDrawerAvailable}>
            {screenIsXl ? (
              <IconButton
                color="inherit"
                aria-label="Open drawer"
                onClick={this.props.showSideDrawer}
                className={classNames(classes.menuButton, {
                  [classes.hide]: sideDrawerAvailable,
                })}
              >
                <Menu />
              </IconButton>
            ) : (
              <div style={{ marginRight: 40 }} />
            )}
            <Typography variant="h6" color="inherit" noWrap>
              {title}
            </Typography>
          </Toolbar>
        </AppBar>
        <Drawer
          variant="permanent"
          className={classNames(classes.drawer, {
            [classes.drawerOpen]: sideDrawerAvailable,
            [classes.drawerClose]: !sideDrawerAvailable,
          })}
          classes={{
            paper: classNames({
              [classes.drawerOpen]: sideDrawerAvailable,
              [classes.drawerClose]: !sideDrawerAvailable,
            }),
          }}
          open={sideDrawerAvailable}
        >
          <div className={classes.toolbar}>
            <IconButton onClick={this.props.hideSideDrawer}>{theme.direction === 'rtl' ? <ChevronRight /> : <ChevronLeft />}</IconButton>
          </div>
          <Divider />
          <List classes={{ root: classes.menuList }}>
            <div>
              {this.navigationItems.map((item) => {
                if (item.requiredPermission) {
                  if (!auth.userHasPermission(item.requiredPermission)) {
                    return null;
                  }
                }
                const selected = location.pathname && location.pathname.startsWith(item.route);
                return (
                  <Tooltip title={item.title} key={item.title} placement="right">
                    <ListItem selected={selected} button onClick={() => this.navigate(item.route)}>
                      <ListItemIcon classes={{ root: classes.menuIconRoot }} className={selected ? classes.menuIconSelected : ''}>
                        <FontAwesomeIcon icon={item.icon} size="3x" />
                      </ListItemIcon>
                      <ListItemText primary={item.title} />
                    </ListItem>
                  </Tooltip>
                );
              })}
            </div>
            <div>
              {auth.userHasRole('admin') && (
                <Tooltip title="Import Auth File" placement="right">
                  <ListItem button onClick={() => this.setState({ showImport: true })}>
                    <ListItemIcon classes={{ root: classes.menuIconRoot }}>
                      <FontAwesomeIcon icon={['fas', 'file-import']} size="2x" />
                    </ListItemIcon>
                    <ListItemText primary="Import Auth File" />
                  </ListItem>
                </Tooltip>
              )}
              {profile && (
                <Tooltip title="Reset Password" placement="right">
                  <ListItem button onClick={() => this.setState({ showPasswordReset: true })}>
                    <ListItemIcon classes={{ root: classes.menuIconRoot }}>
                      <FontAwesomeIcon icon={['fas', 'key']} size="2x" />
                    </ListItemIcon>
                    <ListItemText primary="Reset Password" />
                  </ListItem>
                </Tooltip>
              )}
              <Tooltip title="Logout" placement="right">
                <ListItem button onClick={() => this.navigate('/logout')}>
                  <ListItemIcon classes={{ root: classes.menuIconRoot }}>
                    <FontAwesomeIcon icon={['fas', 'power-off']} size="2x" />
                  </ListItemIcon>
                  <ListItemText primary="Logout" />
                </ListItem>
              </Tooltip>
              <ListItem dense disableGutters>
                <ListItemText classes={{ root: 'text-center' }}>{process.env.REACT_APP_VERSION}</ListItemText>
              </ListItem>
              <ListItem dense disableGutters>
                <ListItemText classes={{ root: 'text-center' }}>{apiVersion}</ListItemText>
              </ListItem>
            </div>
          </List>
        </Drawer>
        <main className={classes.content}>
          <div className={classes.toolbar} />
          <Switch>
            <Route exact path="/intakeRecords" component={IntakeRecordsView} />
            <Route exact path="/intakeRecords/:id" component={IntakeRecordDetailsView} />
            <Route exact path="/benefits" component={cachedWithRouteOnEnter(BenefitsContainer, this.onRouteEnter)} />
            <Route exact path="/benefits/add" component={cachedWithRouteOnEnter(BenefitForm, this.onRouteEnter)} />
            <Route exact path="/benefits/:id/v/:versionId" component={cachedWithRouteOnEnter(BenefitForm, this.onRouteEnter)} />
            <Route exact path="/selfService" component={cachedWithRouteOnEnter(SelfServiceContainer, this.onRouteEnter)} />
            <Route exact path="/selfService/:authId" component={cachedWithRouteOnEnter(SelfServiceDetailsContainer, this.onRouteEnter)} />
            <Route
              exact
              path="/logout"
              render={() => {
                auth.logout();
                return null;
              }}
            />
            <Redirect from="*" to="/intakeRecords" />
          </Switch>
          {profile && (
            <PasswordResetDialog
              open={showPasswordReset}
              error={passwordResetError}
              disabled={isSubmittingPasswordReset}
              onCancel={() => this.setState({ showPasswordReset: false, passwordResetError: null })}
              onSubmit={this.handlePasswordResetRequest}
              email={profile.email}
            />
          )}
          {showImport && (
            <ImportIntakeRecordDialog
              open={showImport}
              error={importError}
              disabled={isImportingRecord}
              onCancel={() => this.setState({ showImport: false, importError: null })}
              onSubmit={this.handleImportRequest}
            />
          )}
        </main>
      </div>
    );
  }
}

AuthenticatedRoutes.propTypes = {
  width: PropTypes.string,
  classes: PropTypes.object.isRequired,
  history: ReactRouterPropTypes.history.isRequired,
  location: ReactRouterPropTypes.location.isRequired,
  theme: PropTypes.object.isRequired,
  title: PropTypes.string,
  apiVersion: PropTypes.string,
  shouldShowSideDrawer: PropTypes.bool.isRequired,
  showSideDrawer: PropTypes.func.isRequired,
  hideSideDrawer: PropTypes.func.isRequired,
  showToast: PropTypes.func.isRequired,
  getUserProfile: PropTypes.func.isRequired,
  getApiVersion: PropTypes.func.isRequired,
  profile: PropTypes.object,
  isImportingRecord: PropTypes.bool.isRequired,
  importIntakeRecord: PropTypes.func.isRequired,
  getIntakeSummaries: PropTypes.func.isRequired,
  getRelocationConsultants: PropTypes.func.isRequired,
  getFirebaseToken: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const { profile } = state.auth;
  const { title, shouldShowSideDrawer, apiVersion } = state.layout;
  const { isImportingRecord } = state.intake;
  return {
    title,
    shouldShowSideDrawer,
    apiVersion,
    profile,
    isImportingRecord,
  };
};

export default compose(
  withStyles(styles, { withTheme: true }),
  withWidth(),
  withRouter,
  connect(mapStateToProps, {
    showSideDrawer,
    hideSideDrawer,
    showToast,
    getUserProfile,
    importIntakeRecord,
    getIntakeSummaries,
    getRelocationConsultants,
    getApiVersion,
    getFirebaseToken,
  }),
)(AuthenticatedRoutes);
