import { array, boolean, object, string } from 'yup';

import { formatForWire } from 'utilities/common';

import { MAX_LENGTH_ERROR_MESSAGE, NULLABLE_BOOLEAN } from 'modules/common/constants';
import Vehicle from './vehicle';

export default class Location {
  static schema = object().shape({
    isHhgServiceInitiated: boolean(),
    largeBulkyItemsRecreationalVehicles: string().max(2000, MAX_LENGTH_ERROR_MESSAGE),
    highValuablesCollections: string().max(500, MAX_LENGTH_ERROR_MESSAGE),
    hasStorageUnits: NULLABLE_BOOLEAN,
    storageUnitContents: string().max(255, MAX_LENGTH_ERROR_MESSAGE),
    storageUnitFacilityName: string().max(50, MAX_LENGTH_ERROR_MESSAGE),
    storageUnitAddress: string().max(50, MAX_LENGTH_ERROR_MESSAGE),
    storageUnitCityStateZip: string().max(50, MAX_LENGTH_ERROR_MESSAGE),
    vehicles: array().of(Vehicle.schema),
  });

  constructor(wireData) {
    const json = wireData || {};
    this.isHhgServiceInitiated = !!json.isHhgServiceInitiated;
    this.largeBulkyItemsRecreationalVehicles = json.largeBulkyItemsRecreationalVehicles || '';
    this.highValuablesCollections = json.highValuablesCollections || '';
    this.hasStorageUnits = json.hasStorageUnits;
    this.storageUnitContents = json.storageUnitContents || '';
    this.storageUnitFacilityName = json.storageUnitFacilityName || '';
    this.storageUnitAddress = json.storageUnitAddress || '';
    this.storageUnitCityStateZip = json.storageUnitCityStateZip || '';
    this.vehicles = json.vehicles ? json.vehicles.map((instance) => new Vehicle(instance)): [];
  }

  static fromWireData(wireData) {
    return new Location(wireData);
  }

  static generateErrors(errors) {
    return errors;
  }

  isPopulated() {
    return this.largeBulkyItemsRecreationalVehicles ||
      this.highValuablesCollections ||
      this.hasStorageUnits !== null ||
      this.storageUnitContents ||
      this.storageUnitFacilityName ||
      this.storageUnitAddress ||
      this.storageUnitCityStateZip ||
      this.vehicles.length > 0;
  }

  toWireData() {
    return formatForWire({
      isHhgServiceInitiated: this.isHhgServiceInitiated,
      largeBulkyItemsRecreationalVehicles: this.largeBulkyItemsRecreationalVehicles,
      highValuablesCollections: this.highValuablesCollections,
      hasStorageUnits: this.hasStorageUnits,
      storageUnitContents: this.storageUnitContents,
      storageUnitFacilityName: this.storageUnitFacilityName,
      storageUnitAddress: this.storageUnitAddress,
      storageUnitCityStateZip: this.storageUnitCityStateZip,
      vehicles: this.vehicles.map((instance) => instance.toWireData()),
    });
  }
}
