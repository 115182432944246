import { boolean, object } from 'yup';

import { formatDateForInput, formatForWire } from 'utilities/common';

import { plusMinusFiveYearsDateSchema } from 'modules/common/constants';

export default class CandidateInternBenefits {
  static schema = object().shape({
    interviewStartDate: plusMinusFiveYearsDateSchema,
    interviewEndDate: plusMinusFiveYearsDateSchema,
    travelStartDate: plusMinusFiveYearsDateSchema,
    travelEndDate: plusMinusFiveYearsDateSchema,
    hasAreaTour: boolean(),
    internProposedStartDate: plusMinusFiveYearsDateSchema,
    internProposedEndDate: plusMinusFiveYearsDateSchema,
  });

  constructor(wireData) {
    const json = wireData || {};
    this.interviewStartDate = formatDateForInput(json.interviewStartDate);
    this.interviewEndDate = formatDateForInput(json.interviewEndDate);
    this.travelStartDate = formatDateForInput(json.travelStartDate);
    this.travelEndDate = formatDateForInput(json.travelEndDate);
    this.hasAreaTour = json.hasAreaTour || false;
    this.internProposedStartDate = formatDateForInput(json.internProposedStartDate);
    this.internProposedEndDate = formatDateForInput(json.internProposedEndDate);
  }

  static fromWireData(wireData) {
    return new CandidateInternBenefits(wireData);
  }

  static generateErrors(errors) {
    return errors;
  }

  toWireData() {
    return formatForWire({
      interviewStartDate: this.interviewStartDate,
      interviewEndDate: this.interviewEndDate,
      travelStartDate: this.travelStartDate,
      travelEndDate: this.travelEndDate,
      hasAreaTour: this.hasAreaTour,
      internProposedStartDate: this.internProposedStartDate,
      internProposedEndDate: this.internProposedEndDate,
    });
  }
}
