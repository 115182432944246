import { Grid, MenuItem, withStyles } from '@material-ui/core';

import { Field } from 'formik';
import { isEqual, values } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import {
  AutocompleteTextField,
  ChipSelect,
  TextField,
  ToggleSelect,
} from 'modules/intake/form';
import { YES_NO_OPTIONS, metadataSort } from 'utilities/common';
import Log from 'utilities/log';

import styles from 'modules/intake/styles';

class PropertyExteriorDetailsForm extends Component {
  shouldComponentUpdate(nextProps) {
    return !isEqual(this.props.exteriorDetails, nextProps.exteriorDetails);
  }

  render() {
    Log.trace('RENDER', 'PropertyExteriorDetailsForm');
    const {
      exteriorDetails,
      rootPath,
      handleChange,
      handleBlur,
      metadata: {
        housePropertyTypes,
        houseSidingTypes,
        houseStyleTypes,
      },
    } = this.props;

    return (
      <>
        <div className="row mb-1">
          <Field
            component={ToggleSelect}
            name={`${rootPath}.housePropertyTypeId`}
            value={exteriorDetails.housePropertyTypeId}
            onChange={handleChange}
            onBlur={handleBlur}
            options={values(housePropertyTypes)}
            variant="spread"
            label="Property Type"
            labelPlacement="top"
          />
        </div>
        <div className="row mb-1">
          <Field
            component={TextField}
            name={`${rootPath}.yearBuilt`}
            label="Year Built"
            value={exteriorDetails.yearBuilt}
            onChange={handleChange}
            onBlur={handleBlur}
            numeric
            inputProps={{
              maxLength: 4,
            }}
            includeThousandsSeparator={false}
          />
          <Field
            component={TextField}
            name={`${rootPath}.purchasedDate`}
            label="Date Purchased"
            value={exteriorDetails.purchasedDate}
            onChange={handleChange}
            onBlur={handleBlur}
            type="date"
          />
          <Field
            component={TextField}
            name={`${rootPath}.purchasePrice`}
            label="Purchase Price"
            value={exteriorDetails.purchasePrice}
            onChange={handleChange}
            onBlur={handleBlur}
            allowDecimal={true}
            numeric
            prefix="$"
          />
        </div>
        <div className="row mb-1 align-end">
          <Grid container>
            <Grid item xs={12} sm={3}>
              <Field
                component={AutocompleteTextField}
                name={`${rootPath}.overallHouseStyle`}
                label="Overall Style"
                value={exteriorDetails.overallHouseStyle}
                onChange={handleChange}
                onBlur={handleBlur}
                suggestions={values(houseStyleTypes)}
                showSuggestionOnFocus={true}
              />
            </Grid>
            <Grid item>
              <Field
                component={ChipSelect}
                name={`${rootPath}.houseSidingTypes`}
                label="Siding Types"
                value={exteriorDetails.houseSidingTypes}
                onChange={handleChange}
                onBlur={handleBlur}
                fullWidth
                labelWidth={82}
              >
                {values(houseSidingTypes).sort(metadataSort).map((type) => (
                  <MenuItem key={type.id} value={type}>
                    {type.description}
                  </MenuItem>
                ))}
              </Field>
            </Grid>
          </Grid>
        </div>
        <div className="row mb-1">
          <Field
            component={TextField}
            name={`${rootPath}.ageOfHouseRoof`}
            label="Age of Roof"
            value={exteriorDetails.ageOfHouseRoof}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <Field
            component={ToggleSelect}
            name={`${rootPath}.hasRoofBeenReplaced`}
            label="Roof Replaced"
            value={exteriorDetails.hasRoofBeenReplaced}
            onChange={handleChange}
            onBlur={handleBlur}
            options={YES_NO_OPTIONS}
            alignCenter
          />
        </div>
      </>
    );
  }
}

PropertyExteriorDetailsForm.propTypes = {
  exteriorDetails: PropTypes.object.isRequired,
  rootPath: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  metadata: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(PropertyExteriorDetailsForm);
